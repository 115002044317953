<i18n>
ru:
  slotNotSelected: '{num} четвертинка'
ua:
  slotNotSelected: '{num} чверть'
us:
  slotNotSelected: '{num} quarter'
</i18n>

<template>
  <div
    v-if="slotBase && slot"
    class="v-slots"
  >
    <div
      v-if="isExtraSmall"
      class="v-slots-quarter-mobile"
    >
      <div class="v-slots-quarter-mobile-title-image">
        <arora-nuxt-image
          :alt="slotBase.Name"
          :image="slotBase.OriginalImage"
          image-type="ProductOriginal"
        />
      </div>
      <div class="v-slots-quarter-mobile-title">
        <h1
          class="v-slots-quarter-mobile-title-main"
          itemprop="name"
          v-html="sanitize(slotBase.Name)"
        />
        <common-quantity-measure
          v-if="slotBase.Quantity > 0"
          :measure="slotBase.Measure"
          :quantity="slotBase.Quantity"
        />
        <common-quantity-measure
          v-else-if="slotBase.Weight > 0"
          :quantity="slotBase.Weight"
        />
      </div>
      <div
        class="v-menu-description v-slots-quarter-mobile-subtitle"
        itemprop="description"
        v-html="sanitize(slotBase.Description)"
      />

      <div class="v-slots-quarter-mobile-buttons">
        <arora-button
          v-for="(selectedSlot, key) in selectedSlotsLocal"
          :key="`${key}-${selectedSlot.ID}`"
          :label="selectedSlot.Name"
          @click="() => selectSlotGroupById(key)"
        >
          <div
            class="v-slot-quarter-name-single--title"
            v-html="selectedSlot.Name"
          />
        </arora-button>
        <arora-button
          v-for="index in Array.from(
            { length: 4 - Object.keys(selectedSlotsLocal).length },
            (_v, i) => 3 - i
          ).reverse()"
          :key="`v-slot-quarter-name-single-${index}`"
          class-name="v-btn-border"
          disabled
          :label="translate('slotsQuarterPage.slotNotSelected', { num: index + 1 })"
          @click="() => console.error('oops')"
        >
          <div
            class="v-slot-quarter-name-single--title"
            v-html="translate('slotsQuarterPage.slotNotSelected', { num: index + 1 })"
          />
        </arora-button>
      </div>

      <template v-if="appConfig.VueSettingsPreRun.MenuSlotsQuarterLayout === 'Osgiliath'">
        <common-cards-flex-mesh
          :items="
            appConfig.VueSettingsPreRun.MenuPageHideStopList
              ? slot.Products.filter((product) => !menuStore.StopListMap.ProductIds.includes(product.ID))
              : slot.Products
          "
          :max-items="3"
          :min-items="3"
        >
          <template #item="item: ProductInList">
            <div
              class="v-slot-product-single"
              :class="{
                'v-active-first':
                  Object.values(selectedSlotsLocal).filter((sel) => sel.ID === item.ID).length === 1 &&
                  selectedSlotsLocal[objectKeys(selectedSlotsLocal)[0]]?.ID === item.ID,
                'v-active-second':
                  Object.values(selectedSlotsLocal).filter((sel) => sel.ID === item.ID).length === 1 &&
                  selectedSlotsLocal[objectKeys(selectedSlotsLocal)[1]]?.ID === item.ID,
                'v-active-third':
                  Object.values(selectedSlotsLocal).filter((sel) => sel.ID === item.ID).length === 1 &&
                  selectedSlotsLocal[objectKeys(selectedSlotsLocal)[2]]?.ID === item.ID,
                'v-active-fourth':
                  Object.values(selectedSlotsLocal).filter((sel) => sel.ID === item.ID).length === 1 &&
                  selectedSlotsLocal[objectKeys(selectedSlotsLocal)[3]]?.ID === item.ID,
                'v-active-multiple':
                  Object.values(selectedSlotsLocal).filter((sel) => sel.ID === item.ID).length > 1,
                disabled: menuStore.StopListMap.ProductIds.includes(item.ID)
              }"
              data-test-id="slots-quarter-add-product"
              @click="() => selectSlot(slot?.ID, item)"
            >
              <div
                v-if="
                  Object.values(selectedSlotsLocal).some(
                    (slotLocal: ProductInList) => slotLocal.ID === item.ID
                  )
                "
                class="v-slot-quarter-image"
              >
                <arora-nuxt-image
                  :key="item.ID"
                  disable-lazy
                  :image="
                    imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage
                  "
                  :alt="item.Name"
                />
                <div
                  v-if="Object.values(selectedSlotsLocal).filter((sel) => sel.ID === item.ID).length > 1"
                  class="v-active-multiple-number"
                >
                  {{ Object.values(selectedSlotsLocal).filter((sel) => sel.ID === item.ID).length }}
                </div>
                <arora-nuxt-image
                  v-else
                  :key="item.ID"
                  disable-lazy
                  :image="
                    imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage
                  "
                  :alt="item.Name"
                />
              </div>
              <arora-nuxt-image
                v-else
                :key="item.ID"
                :image="
                  imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage
                "
                :alt="item.Name"
                :image-type="imageExists(item.CombineMobileImage) ? 'CombineMobile' : 'ProductNormal'"
              />
              <div
                class="v-slot-product-single-title"
                v-html="sanitize(item.Name)"
              />
              <div class="v-slot-product-single-subtitle">
                <template v-if="slot.Products.some((sp) => sp.Price > 0)">
                  <common-currency
                    v-if="item.Price > 0"
                    :amount="item.Price"
                  />
                  <span
                    v-else
                    v-html="translate('slotsLunchPage.free')"
                  />
                </template>
                <common-quantity-measure
                  v-else-if="item.Quantity > 0"
                  :measure="item.Measure"
                  :quantity="item.Quantity"
                />
                <common-quantity-measure
                  v-else-if="item.Weight > 0"
                  :quantity="item.Weight"
                />
              </div>
            </div>
          </template>
        </common-cards-flex-mesh>
      </template>
      <template v-else-if="appConfig.VueSettingsPreRun.MenuSlotsQuarterLayout === 'RedhornGate'">
        <div
          v-for="item in slot.Products"
          :key="item.ID"
        >
          <div
            class="v-slot-product-single v-slot-product-single-mobile"
            :class="{
              'v-active': Object.values(selectedSlotsLocal).some((sel) => sel.ID === item.ID)
            }"
            data-test-id="slots-quarter-add-product"
          >
            <lazy-menu-product-slots-selected-info
              :selected-slot="item"
              @click="() => selectSlot(slot?.ID, item)"
            />
          </div>
          <hr />
        </div>
      </template>

      <arora-button
        class-name="v-w-100 v-btn-lg"
        :disabled="slotsNotReady"
        :label="translate('addToCartButton.addToCart')"
        @click="async () => await addToBasket()"
      >
        <template v-if="price > 0">
          <span v-html="translate('slotsLunchPage.addToCart')" />
          <common-currency :amount="price" />
        </template>
        <span
          v-else
          v-html="translate('addToCartButton.addToCart')"
        />
      </arora-button>
    </div>
    <div
      v-else
      class="v-slots-quarter-big"
    >
      <div class="v-slots-quarter-big-variants">
        <h1
          class="v-slots-quarter-big-title"
          v-html="sanitize(slotBase.Name)"
        />
        <div class="v-slots-quarter-big-scroll-area v-scrollbar v-pm-shadow">
          <menu-product-slots-quarter-osgiliath
            v-if="appConfig.VueSettingsPreRun.MenuSlotsQuarterLayout === 'Osgiliath'"
            :quarter="slot"
            :select-slot-group="selectSlotGroup"
            :selected-slots="selectedSlotsLocal"
            :slot-base="slotBase"
          />
          <menu-product-slots-quarter-redhorn-gate
            v-else-if="appConfig.VueSettingsPreRun.MenuSlotsQuarterLayout === 'RedhornGate'"
            :quarter="slot"
            :select-slot-group="selectSlotGroup"
            :selected-slots="selectedSlotsLocal"
            :slot-base="slotBase"
          />
        </div>
      </div>
      <div class="v-slots-quarter-big-confirm">
        <div class="v-slots-quarter-big-confirm-image v-mb-xl">
          <menu-product-slots-quarter-images
            :select-slot-group="selectSlotGroupById"
            :selected-slots="selectedSlotsLocal"
          />
          <lazy-common-random-cube-button
            class-name="v-btn v-btn-border"
            @click="async () => await randomize()"
          />
        </div>
        <template v-if="!stringIsNullOrWhitespace(slotBase.PersonalProductMessage)">
          <div class="v-slots-quarter-message">
            <icon-menu-info class="v-mr-xs" />
            <div class="v-d-flex v-align-items-center">
              <span v-html="sanitize(slotBase.PersonalProductMessage)" />
            </div>
          </div>
        </template>

        <template v-if="!stringIsNullOrWhitespace(slotBase.NoOrderTypeReasonText)">
          <div class="v-product-card-no-pickup-or-delivery-reason-text v-xs-small">
            <span v-html="sanitize(slotBase.NoOrderTypeReasonText)" />
          </div>
        </template>

        <div class="v-slot-quarter-name">
          <div
            v-for="(selectedSlot, key) in selectedSlotsLocal"
            :key="`${key}-${selectedSlot.ID}`"
            class="v-slot-quarter-name-single"
          >
            <div
              class="v-slot-quarter-name-single--title"
              v-html="selectedSlot.Name"
            />
            <div
              v-if="selectedSlot.Price > 0"
              class="v-slot-quarter-name-single--subtitle"
            >
              <common-currency :amount="selectedSlot.Price" />
            </div>
          </div>
          <div
            v-for="index in Array.from(
              { length: 4 - Object.keys(selectedSlotsLocal).length },
              (_v, i) => 3 - i
            ).reverse()"
            :key="`v-slot-quarter-name-single-${index}`"
            class="v-slot-quarter-name-single disabled"
          >
            <div
              class="v-slot-quarter-name-single--title"
              v-html="translate('slotsQuarterPage.slotNotSelected', { num: index + 1 })"
            />
          </div>
        </div>
        <div class="v-slots-half-big-selection-button-confirm v-mb-xs">
          <div
            v-if="baseProduct"
            class="v-w-100 v-d-flex v-flex-row"
            data-test-id="product-half-option"
          >
            <menu-option-selector
              :options="baseProduct.Options"
              :product="baseProduct"
              :threshold="appConfig.VueSettingsPreRun.ProductOptionThreshold"
              data-test-id="product-page-options"
            />
          </div>
          <div
            v-if="isProductUnavailable(slotBase)"
            class="v-flex-100 v-d-flex v-flex-row"
          >
            <div
              v-if="menuStore.StopListMap.ProductIds.includes(slotBase.ID)"
              class="v-nomenclature-card-in-stop-list v-product-in-stop-list v-btn-lg"
              data-test-id="product-in-stop-list"
            >
              <span v-html="translate('productCard.inStopListButton')" />
            </div>
            <common-popover
              v-else
              class="v-w-100"
            >
              <template #hoverable>
                <div
                  class="v-nomenclature-card-in-stop-list v-product-in-stop-list"
                  data-test-id="slot-half-card-unavailable-by-order-type"
                >
                  <span v-html="translate('productCard.notAvailableByOrderTypeButton')" />
                </div>
              </template>
              <template #content>
                <span
                  v-html="
                    clientStore.orderTypeNotSelected
                      ? translate('productCard.notAvailableByOrderType')
                      : translate('productCard.notAvailableByOrderTypeTooltip', {
                          orderType: translate(clientStore.orderTypeText)
                        })
                  "
                />
              </template>
            </common-popover>
          </div>
          <arora-button
            v-else
            class-name="v-w-100"
            :disabled="slotsNotReady"
            :label="translate('addToCartButton.addToCart')"
            data-test-id="slots-quarter-buy"
            @click="async () => await addToBasket()"
          >
            <div class="v-align-currency-center">
              <template v-if="price > 0">
                <span
                  v-html="
                    translate(
                      slotsNotReady ? 'slotsLunchPage.addToCartFrom' : 'slotsLunchPage.addToCart'
                    )
                  "
                />
                <common-currency :amount="price" />
              </template>
              <span
                v-else
                v-html="translate('addToCartButton.addToCart')"
              />
            </div>
          </arora-button>
        </div>
        <div class="v-slots-half-big-selection-button-reset">
          <arora-button
            class-name="v-btn v-btn-link v-w-100"
            ignore-settings
            :label="translate('slotsLunchPage.redo')"
            @click="async () => await clearSlots()"
          >
            <div class="v-d-flex v-align-center">
              <icon-general-sync class="v-mr-xs" />
              <span v-html="translate('slotsLunchPage.redo')" />
            </div>
          </arora-button>
        </div>
      </div>
    </div>
  </div>
  <common-skeleton v-else />
</template>

<script setup lang="ts">
import { FirstAddType } from '~api/consts'

import type { CartSlot } from '~types/clientStore'
import type { ProductInList, Slot, SlotBase } from '~types/menuStore'
import type { SlotsCommon } from '~types/props'

import { type GUID, useCommon, useWindowSize } from '@arora/common'

const { slotId, isRecommendation } = defineProps<SlotsCommon>()

const clientStore = useClientStore()
const menuStore = useMenuStore()
const popupStore = usePopupStore()

const { translate, sanitize } = useI18nSanitized()
const { isExtraSmall } = useWindowSize()
const { objectKeys, stringIsNullOrWhitespace, getRandomInt } = useCommon()
const { imageExists } = useImageInfo()
const { fromPopup } = useInstance()
const { addToCartWithPayload } = useCartFunctionsWithDialogs()
const { isProductUnavailable, viewProduct } = useProduct()
const appConfig = useAppConfig()

const slotBase = ref<SlotBase | undefined>()
const slot = ref<Slot | null>(null)
const baseProduct = ref<ProductInList | undefined>()

const { makeDefaultOption } = useProduct()

onMounted(async () => {
  baseProduct.value = appConfig.Products.find((product) => product.ID === slotId)
  const optionFromStore = menuStore.SelectedOptionsPerProduct.get(slotId)

  slotBase.value = appConfig.Slots.find(
    (slot) => slot.ID === (optionFromStore ? optionFromStore.ID : slotId)
  )

  if (slotBase.value) {
    slot.value = slotBase.value.Slots[0]
    makeDefaultOption(slotBase.value)

    viewProduct(slotBase.value, price.value)
  }
})

watch(
  () => menuStore.SelectedOptionsPerProduct.get(slotId)?.ID,
  async (value, oldValue) => {
    await clearSlots()
    if (value && value !== oldValue && oldValue !== undefined) {
      slotBase.value = appConfig.Slots.find((slot) => slot.ID === value)
      if (slotBase.value) {
        slot.value = slotBase.value.Slots[0]
      }
    }
  }
)

const price = computed<number>(() => {
  if (!slotBase.value) return 0

  let price = slotBase.value?.Price ?? 0

  for (const key of objectKeys(selectedSlotsLocal.value)) {
    price += selectedSlotsLocal.value[key]?.Price ?? 0
  }

  return price + (slotBase.value.Price > 0 ? slotBase.value.Price : (baseProduct.value?.Price ?? 0))
})
const slotsNotReady = computed<boolean>(
  () =>
    objectKeys(selectedSlotsLocal.value).length !==
    (slotBase.value?.Slots ?? []).filter((s) => s.IsRequired).length
)

const selectedSlotsLocal = ref<Record<GUID, ProductInList>>({})

function selectSlotGroup(newSlot: Slot): void {
  slot.value = newSlot
}

function selectSlotGroupById(newSlotId: GUID): void {
  for (const slotLocal of slotBase.value?.Slots ?? []) {
    if (slotLocal.ID === newSlotId) {
      selectSlotGroup(slotLocal)

      return
    }
  }
}

let nextIndex = 1

function selectSlot(slotId: GUID | undefined, item: ProductInList): void {
  if (!slotBase.value || !slotId) return

  if (!appConfig.VueSettingsPreRun.MenuSlotsAllowDuplicates)
    for (const key in selectedSlotsLocal.value) {
      if (selectedSlotsLocal.value[key as GUID].ID === item.ID) {
        delete selectedSlotsLocal.value[key as GUID]
        if (nextIndex === 0) {
          nextIndex = 3
        } else {
          nextIndex--
        }
        const slotGroup = (slotBase.value?.Slots ?? []).find((s) => s.ID === key)
        if (slotGroup) selectSlotGroup(slotGroup)

        return
      }
    }

  selectedSlotsLocal.value[slotId] = item

  const slotsLocal: Slot[] = (slotBase.value?.Slots ?? []).sort((s) => s.SortWeight)
  selectSlotGroup(slotsLocal[nextIndex])
  nextIndex++
  if (nextIndex === 4) {
    nextIndex = 0
  }
}

async function addToBasket(): Promise<void> {
  if (!slotBase.value || !slot.value) return

  const slotsForSend: CartSlot[] = []

  for (const key of objectKeys(selectedSlotsLocal.value)) {
    const slotByKey = slotBase.value.Slots.find((s) => s.ID === key)

    if (slotByKey)
      slotsForSend.push({
        ID: slotByKey.ID,
        IsRequired: slotByKey.IsRequired,
        Modifiers: {},
        ProductID: selectedSlotsLocal.value[key].ID
      })
  }

  const added = await addToCartWithPayload({
    product: {
      productID: slotId,
      optionID: menuStore.SelectedOptionsPerProduct.get(slotId)?.ID,
      count: 1,
      groupId: slotBase.value.GroupID,
      name: slotBase.value.Name,
      priceModified: price.value
    },
    slots: slotsForSend,
    firstAddType: isRecommendation ? FirstAddType.Recommendation : FirstAddType.Default
  })

  if (added && fromPopup.value) {
    popupStore.closePopup()
  }
}

async function clearSlots(): Promise<void> {
  selectedSlotsLocal.value = {}
  slot.value = slotBase.value?.Slots[0] ?? null
}

async function randomize(): Promise<void> {
  if (!slotBase.value) return
  await clearSlots()

  const slots: Slot[] = slotBase.value?.Slots ?? []
  for (const slot of slots) {
    let item: ProductInList
    do {
      const index = getRandomInt(slot.Products.length)
      item = slot.Products[index]
    } while (
      appConfig.VueSettingsPreRun.MenuSlotsAllowDuplicates
        ? false
        : Object.values(selectedSlotsLocal.value).some(
            (slotLocal: ProductInList) => slotLocal.ID === item.ID
          )
    )

    selectedSlotsLocal.value[slot.ID] = item
  }
}
</script>

<style lang="scss">
@use '~/assets/variables';

.v-slot-product-single {
  cursor: pointer;
  height: 100%;

  &.v-active {
    border: 1.5px solid variables.$PrimaryBackgroundColor;
    border-radius: variables.$BorderRadius;
  }

  .v-slot-quarter-image {
    display: grid;
    grid-template-areas: 'pic';
    padding: 0 0.5rem;
  }

  &.v-active-multiple {
    .v-broken-image {
      grid-area: pic;
    }

    .v-active-multiple-number {
      grid-area: pic;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 50px;
      font-weight: 600;
    }

    .v-img-fluid {
      grid-area: pic;

      opacity: 0.5;
    }
  }

  &.v-active-first {
    .v-broken-image {
      grid-area: pic;
    }

    .v-img-fluid {
      grid-area: pic;

      opacity: 0.5;

      &:nth-child(1) {
        opacity: 1;
        clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 100%, 0 100%, 0 50%);
      }
    }
  }

  &.v-active-second {
    .v-broken-image {
      grid-area: pic;
    }

    .v-img-fluid {
      grid-area: pic;

      opacity: 0.5;

      &:nth-child(1) {
        opacity: 1;
        clip-path: polygon(50% 0, 50% 50%, 100% 50%, 100% 100%, 0 100%, 0 0);
      }
    }
  }

  &.v-active-third {
    .v-broken-image {
      grid-area: pic;
    }

    .v-img-fluid {
      grid-area: pic;

      opacity: 0.5;

      &:nth-child(1) {
        opacity: 1;
        clip-path: polygon(100% 0, 100% 100%, 50% 100%, 50% 50%, 0 50%, 0 0);
      }
    }
  }

  &.v-active-fourth {
    .v-broken-image {
      grid-area: pic;
    }

    .v-img-fluid {
      grid-area: pic;

      opacity: 0.5;

      &:nth-child(1) {
        opacity: 1;
        clip-path: polygon(100% 0, 100% 50%, 50% 50%, 50% 100%, 0 100%, 0 0);
      }
    }
  }

  &-title {
    text-align: center;
    margin-bottom: 10px;
  }

  &-description {
    text-align: left;
    margin: 15px;
    color: variables.$BodyTextColorLight;

    flex: 1 0 auto;
  }

  &-under-description {
    display: flex;
    justify-content: space-between;
    margin: 0 15px 24px;

    &-button {
      i {
        font-size: 24px;
        color: variables.$BodyTextColorLight;

        &.v-active {
          color: variables.$PrimaryBackgroundColor;
        }
      }
    }
  }

  &-subtitle {
    text-align: center;
    margin-bottom: 1.1rem;
    color: variables.$BodyTextColorLight;
  }

  .v-img-fluid {
    border-radius: variables.$BorderRadius;
  }
}

.v-slots-quarter-big {
  display: flex;

  min-height: 525px;
  gap: 10px;

  .v-slots-quarter-big-title {
    font-size: variables.$TextSizeH3;
    font-weight: 600;
    margin-bottom: 16px;
  }

  &-variants {
    flex: 0 0 calc(60% - 10px);
    max-width: calc(60% - 10px);
  }

  &-scroll-area {
    max-height: 500px;
    overflow-y: scroll;
  }

  &-confirm {
    flex: 0 0 40%;
    max-width: 40%;
    padding-left: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .v-slots-quarter-big-confirm-image {
      position: relative;

      .v-random-cube-wrapper {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    .v-slots-quarter-message {
      display: flex;
      width: 100%;
      color: variables.$BodyTextColorLight;
      font-size: variables.$TextSizeLabel;
      padding: 10px;

      svg {
        width: 16px;
        height: 16px;
        flex: 0 0 16px;
      }
    }

    .v-slot-quarter-name {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;

      &-single {
        flex: 0 0 calc(50% - 10px);
        max-width: calc(50% - 10px);

        padding: 0 10px 10px;
        font-size: variables.$TextSizeMain;

        &:nth-child(1) {
          border-bottom: 1px solid variables.$BorderColor;
        }

        &:nth-child(2) {
          border-bottom: 1px solid variables.$BorderColor;
        }

        &--title {
          font-size: 1.1rem;
          margin-bottom: 5px;
        }

        &--subtitle {
          font-size: 0.9rem;

          .v-currency-wrapper {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .v-btn {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      gap: 3px;
    }
  }
}

.v-slots-quarter-mobile {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .v-slots-quarter-mobile-title-image {
    flex: 0 0 100%;
    max-width: 100%;
    border-radius: variables.$BorderRadius;
    overflow: hidden;
  }

  .v-slots-quarter-mobile-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .v-slots-quarter-mobile-title-main {
      font-size: variables.$TextSizeH3;
      font-weight: 600;
    }
  }

  &-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    .v-btn {
      flex: 0 0 calc(50% - 15px);
      max-width: calc(50% - 15px);
    }
  }

  .v-slots-quarter-mobile-subtitle {
    color: variables.$BodyTextColorLight;
  }
}

.v-slot-product-single-mobile {
  padding: 10px;

  &.v-active {
    border: 1.5px solid variables.$PrimaryBackgroundColor;
    border-radius: variables.$BorderRadius;
  }
}
</style>
