<i18n>
ru:
  inStopList: Недоступен
  minModTextOne: Необходимо выбрать минимум 1 ингредиент
  minModText: 'Необходимо выбрать ингредиентов – {count}'
ua:
  inStopList: Недоступний
  minModTextOne: Необхідно вибрати мінімум 1 інгредієнт
  minModText: 'Необхідно вибрати інгредієнтів – {count}'
us:
  inStopList: Not available
  minModTextOne: You must choose at least 1 ingredient
  minModText: 'Need to select ingredients – {count}'
</i18n>

<template>
  <div
    v-if="loaded"
    class="v-product-modifiers-wrapper"
    data-test-id="product-modifiers-wrapper"
  >
    <div
      v-if="minModifiersCount !== 0"
      class="v-d-flex v-align-items-center v-mb-md"
    >
      <icon-general-information-circle
        class="v-mod-group-icon v-mr-xs v-select-none"
        :class="[
          modifiersTotalCounts < minModifiersCount ? 'v-error-color' : 'v-mod-group-warning-disabled'
        ]"
      />
      <p
        class="v-mod-group-text v-select-none"
        :class="[
          modifiersTotalCounts < minModifiersCount ? 'v-error-color' : 'v-mod-group-warning-disabled'
        ]"
        v-html="
          minModifiersCount === 1
            ? translate('modifiers.minModTextOne')
            : translate('modifiers.minModText', { count: minModifiersCount })
        "
      />
    </div>
    <div
      v-for="modifiersGroup in modifiersGroupsLocal"
      :key="sanitize(modifiersGroup.ID)"
      class="v-row v-mb-sm"
    >
      <div
        v-if="!stringIsNullOrWhitespace(sanitize(modifiersGroup.Name))"
        class="v-d-flex v-justify-content-between v-mb-xs"
      >
        <div
          class="v-mod-group-title v-select-none"
          v-html="sanitize(modifiersGroup.Name)"
        />
        <div
          v-if="modifiersGroup.MaxCount && modifiersGroup.MaxCount < 50"
          class="v-mod-group-count v-select-none"
          v-html="`${selectedModsByCategory[modifiersGroup.ID]} / ${modifiersGroup.MaxCount}`"
        />
      </div>
      <div
        v-if="modifiersGroup.MinCount && modifiersGroup.MinCount > 0"
        class="v-d-flex v-align-items-center v-mb-sm"
      >
        <icon-general-information-circle
          class="v-mod-group-icon v-mr-xs v-select-none"
          :class="[
            modifiersGroup.MinCount > selectedModsByCategory[modifiersGroup.ID]
              ? 'v-error-color'
              : 'v-mod-group-warning-disabled'
          ]"
        />
        <p
          class="v-mod-group-text v-select-none"
          :class="[
            modifiersGroup.MinCount > selectedModsByCategory[modifiersGroup.ID]
              ? 'v-error-color'
              : 'v-mod-group-warning-disabled'
          ]"
          v-html="
            modifiersGroup.MinCount === 1
              ? translate('modifiers.minModTextOne')
              : translate('modifiers.minModText', { count: modifiersGroup.MinCount })
          "
        />
      </div>
      <div class="v-product-modifiers-group">
        <div
          v-for="modifier in appConfig.VueSettingsPreRun.MenuPageHideStopList
            ? modifiersGroup.Modifiers.filter(
                (mod) => !menuStore.StopListMap.ModifierIds.includes(mod.ID)
              )
            : modifiersGroup.Modifiers"
          :key="sanitize(modifier.ID)"
          class="v-item-row"
          :class="{
            'v-item-row__in-one-row':
              appConfig.VueSettingsPreRun.ProductLayout === 'KhazadDum' ||
              appConfig.VueSettingsPreRun.ProductLayout === 'Mazarbul',
            disabled: menuStore.StopListMap.ModifierIds.includes(modifier.ID)
          }"
          data-test-id="product-modifier"
        >
          <div class="v-modifier">
            <div class="v-image-mod">
              <arora-nuxt-image
                :alt="sanitize(modifier.Title)"
                :image="modifier.Image"
              />
            </div>
            <div class="v-right-mod">
              <div
                class="v-title-mod v-select-none"
                v-html="sanitize(modifier.Title)"
              />
              <div
                v-if="menuStore.StopListMap.ModifierIds.includes(modifier.ID)"
                class="v-modifier-in-stop-list"
                v-html="translate('modifiers.inStopList')"
              />
              <div
                v-else
                class="v-price-count-block"
              >
                <div class="v-price-block">
                  <common-currency :amount="modifier.PriceModified" />
                </div>
                <div
                  class="v-quantity"
                  data-test-id="product-modifier-quantity"
                >
                  <arora-button
                    v-if="modifiersCountsLocal[modifier.ID] > 0"
                    class-name="increment-button v-modifier-minus"
                    :disabled="modifiersCountsLocal[modifier.ID] === modifier.ValueMin"
                    data-test-id="product-modifier-decrement"
                    label="-"
                    @click="() => modifiersCountsLocalUpdate(false, modifier.ID, modifiersGroup.ID)"
                  >
                    <icon-general-minus />
                  </arora-button>

                  <div
                    v-if="modifiersCountsLocal[modifier.ID] > 0"
                    class="v-count-modifiers"
                  >
                    <input
                      class="v-form-control v-no-style v-text-center"
                      readonly
                      v-model="modifiersCountsLocal[modifier.ID]"
                    />
                  </div>

                  <arora-button
                    class-name="increment-button v-modifier-plus"
                    :disabled="
                      (maxModifiersCount !== 0 && maxModifiersCount === modifiersTotalCounts) ||
                      modifiersCountsLocal[modifier.ID] === modifier.ValueMax ||
                      (!!modifiersGroup.MaxCount &&
                        selectedModsByCategory[modifiersGroup.ID] >= modifiersGroup.MaxCount)
                    "
                    data-test-id="product-modifier-increment"
                    label="+"
                    @click="() => modifiersCountsLocalUpdate(true, modifier.ID, modifiersGroup.ID)"
                  >
                    <icon-general-plus />
                  </arora-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <common-skeleton v-else />
</template>

<script setup lang="ts">
import type { ModifierCounts } from '~types/clientStore'
import type { ModifierGroup } from '~types/menuStore'

import { type GUID, useCommon } from '@arora/common'

const { productId, modifiersGroups, minModifiersCount } = defineProps<{
  productId: GUID
  modifiersGroups: ModifierGroup[]
  maxModifiersCount: number
  minModifiersCount: number
}>()

const loaded = ref<boolean>(false)

const appConfig = useAppConfig()
const menuStore = useMenuStore()

const { stringIsNullOrWhitespace } = useCommon()
const { translate, sanitize } = useI18nSanitized()

const modifiersGroupsLocal = computed<ModifierGroup[]>(() => {
  return modifiersGroups.filter((item) => item.Modifiers.length > 0)
})

const modifiersCountsLocal = computed<ModifierCounts>({
  get() {
    const inStoreMap = menuStore.SelectedModifiersPerProduct.get(productId)
    if (inStoreMap) {
      return inStoreMap
    }

    return {}
  },
  set(value) {
    if (value) {
      menuStore.SelectedModifiersPerProduct.set(productId, value)
    } else {
      menuStore.SelectedModifiersPerProduct.set(productId, {})
    }
  }
})

const modifiersTotalCounts = computed(() => {
  return Object.values(modifiersCountsLocal.value).reduce((totalCount, modifierCount) => {
    return totalCount + modifierCount
  }, 0)
})
const selectedModsByCategory = ref<ModifierCounts>({})
onMounted(() => {
  if (modifiersGroupsLocal.value) {
    let disabledButton = false

    for (const modifiersGroup of modifiersGroupsLocal.value) {
      for (const modifier of modifiersGroup.Modifiers) {
        if (!modifiersCountsLocal.value[modifier.ID] || modifiersCountsLocal.value[modifier.ID] === 0)
          modifiersCountsLocal.value[modifier.ID] = modifier.ValueDefault
      }
      const idSet = new Set(modifiersGroup.Modifiers.map((object) => object.ID))
      let count = 0
      Object.keys(modifiersCountsLocal.value).map((key) => {
        if (idSet.has(key as GUID)) {
          count += modifiersCountsLocal.value[key as GUID]
        }
      })
      selectedModsByCategory.value[modifiersGroup.ID] = count
      if (modifiersGroup.MinCount && modifiersGroup.MinCount > count) disabledButton = true
    }

    menuStore.disabledButtonIfRequiredModsNotSelected =
      minModifiersCount !== 0 && modifiersTotalCounts.value < minModifiersCount ? true : disabledButton
  }
  loaded.value = true
})

async function modifiersCountsLocalUpdate(increment: boolean, id: GUID, groupID: GUID): Promise<void> {
  const modifiersCountsChanged = modifiersCountsLocal.value

  if (increment) {
    modifiersCountsChanged[id]++
    selectedModsByCategory.value[groupID]++
  } else {
    modifiersCountsChanged[id]--
    selectedModsByCategory.value[groupID]--
  }
  let disabledButton = false
  for (const modifiersGroup of modifiersGroupsLocal.value) {
    if (
      modifiersGroup.MinCount &&
      modifiersGroup.MinCount > selectedModsByCategory.value[modifiersGroup.ID]
    )
      disabledButton = true
  }
  menuStore.disabledButtonIfRequiredModsNotSelected =
    minModifiersCount !== 0 && modifiersTotalCounts.value < minModifiersCount ? true : disabledButton

  modifiersCountsLocal.value = modifiersCountsChanged
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-product-modifiers-wrapper {
  .v-mod-group-title {
    font-size: 1rem;
    font-weight: 500;
  }
  .v-mod-group-icon {
    width: 1.15rem;
    height: 1.15rem;
  }
  .v-mod-group-text {
    margin: 0;
    font-size: 0.9rem;
  }
  .v-mod-group-warning-disabled {
    opacity: 0.5;
  }
  .v-product-modifiers-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    .v-item-row {
      display: flex;
      flex-direction: row;
      flex: 0 0 50%;
      max-width: 50%;

      padding-left: 0.5rem;
      padding-right: 0.5rem;

      margin-bottom: 1rem;

      &__in-one-row {
        flex: 0 0 100%;
        max-width: 100%;
      }

      @include mixins.sm {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .v-modifier {
        box-shadow: variables.$CardShadow;
        border-radius: variables.$BorderRadius;
        border: 1px solid transparent;
        width: 100%;
        display: flex;
        flex-direction: row;
        @include mixins.trans;

        &:hover {
          box-shadow: none;
          border: 1px solid variables.$BorderColor;
        }

        .v-image-mod {
          flex: 0 0 33%;
          max-width: 33%;
        }

        .v-right-mod {
          flex: 0 0 67%;
          max-width: 67%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 12px;

          .v-title-mod {
            font-weight: 500;
            font-size: 0.9rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .v-price-count-block {
            font-size: 0.9rem;
            display: flex;
            justify-content: space-between;

            .v-quantity {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;

              .v-count-modifiers {
                flex: 0 0 20px;
                max-width: 20px;
                align-items: center;
              }

              .increment-button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                padding: 0;
                font-size: variables.$TextSizeLabel;
                background: transparent;
                color: variables.$BodyTextColorLight;
                border: 1px solid variables.$BodyTextColorLight;

                svg {
                  fill: none;
                  width: 10px;
                  height: 10px;

                  path {
                    stroke: variables.$BodyTextColorLight;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
