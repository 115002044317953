<template>
  <common-cards-flex-mesh
    :items="
      appConfig.VueSettingsPreRun.MenuPageHideStopList
        ? quarter.Products.filter((product) => !menuStore.StopListMap.ProductIds.includes(product.ID))
        : quarter.Products
    "
    :max-items="3"
    :min-items="3"
  >
    <template #item="item: ProductInList">
      <div
        class="v-slot-product-single"
        :class="{
          'v-active-first':
            Object.values(selectedSlotsLocal).filter((sel) => sel.ID === item.ID).length === 1 &&
            selectedSlotsLocal[objectKeys(selectedSlotsLocal)[0]]?.ID === item.ID,
          'v-active-second':
            Object.values(selectedSlotsLocal).filter((sel) => sel.ID === item.ID).length === 1 &&
            selectedSlotsLocal[objectKeys(selectedSlotsLocal)[1]]?.ID === item.ID,
          'v-active-third':
            Object.values(selectedSlotsLocal).filter((sel) => sel.ID === item.ID).length === 1 &&
            selectedSlotsLocal[objectKeys(selectedSlotsLocal)[2]]?.ID === item.ID,
          'v-active-fourth':
            Object.values(selectedSlotsLocal).filter((sel) => sel.ID === item.ID).length === 1 &&
            selectedSlotsLocal[objectKeys(selectedSlotsLocal)[3]]?.ID === item.ID,
          'v-active-multiple':
            Object.values(selectedSlotsLocal).filter((sel) => sel.ID === item.ID).length > 1,
          disabled: menuStore.StopListMap.ProductIds.includes(item.ID)
        }"
        data-test-id="slots-quarter-add-product"
        @click="() => selectSlot(quarter?.ID, item)"
      >
        <div class="v-slot-quarter-image">
          <template
            v-if="
              Object.values(selectedSlotsLocal).some(
                (slotLocal: ProductInList) => slotLocal.ID === item.ID
              )
            "
          >
            <arora-nuxt-image
              :key="item.ID"
              disable-lazy
              disable-resize
              :alt="item.Name"
              :image="imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage"
            />
            <div
              v-if="Object.values(selectedSlotsLocal).filter((sel) => sel.ID === item.ID).length > 1"
              class="v-active-multiple-number"
            >
              {{ Object.values(selectedSlotsLocal).filter((sel) => sel.ID === item.ID).length }}
            </div>
            <arora-nuxt-image
              v-else
              :key="item.ID"
              disable-lazy
              disable-resize
              :alt="item.Name"
              :image="imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage"
            />
          </template>
          <arora-nuxt-image
            v-else
            :key="item.ID"
            :alt="item.Name"
            :image="imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage"
            :image-type="imageExists(item.CombineMobileImage) ? 'CombineMobile' : 'ProductNormal'"
          />
        </div>

        <div
          class="v-slot-product-single-title"
          v-html="sanitize(item.Name)"
        />
        <div
          class="v-slot-product-single-subtitle v-d-flex v-align-items-center v-justify-content-center"
        >
          <common-currency
            v-if="item.Price > 0"
            :amount="item.Price"
          />
          <common-quantity-measure
            v-else-if="item.Quantity > 0"
            :measure="item.Measure"
            :quantity="item.Quantity"
          />
          <common-quantity-measure
            v-else-if="item.Weight > 0"
            :quantity="item.Weight"
          />
        </div>
      </div>
    </template>
  </common-cards-flex-mesh>
</template>

<script setup lang="ts">
import type { ProductInList, Slot, SlotBase } from '~types/menuStore'

import { type GUID, useCommon } from '@arora/common'

const { slotBase, selectedSlots, selectSlotGroup } = defineProps<{
  quarter: Slot
  slotBase: SlotBase
  selectSlotGroup: (newSlot: Slot) => void
  selectedSlots: Record<GUID, ProductInList>
}>()

const appConfig = useAppConfig()

const { sanitize } = useI18nSanitized()
const { objectKeys } = useCommon()
const { imageExists } = useImageInfo()
const menuStore = useMenuStore()

let nextIndex = 1

const selectedSlotsLocal = ref<Record<GUID, ProductInList>>(selectedSlots)

function selectSlot(slotId: GUID | undefined, item: ProductInList): void {
  if (!slotBase || !slotId) return

  if (!appConfig.VueSettingsPreRun.MenuSlotsAllowDuplicates)
    for (const key in selectedSlotsLocal.value) {
      if (selectedSlotsLocal.value[key as GUID].ID === item.ID) {
        delete selectedSlotsLocal.value[key as GUID]
        if (nextIndex === 0) {
          nextIndex = 3
        } else {
          nextIndex--
        }
        const slotGroup = (slotBase?.Slots ?? []).find((s) => s.ID === key)
        if (slotGroup) selectSlotGroup(slotGroup)

        return
      }
    }

  selectedSlotsLocal.value[slotId] = item

  const slotsLocal: Slot[] = (slotBase?.Slots ?? []).sort((s) => s.SortWeight)
  selectSlotGroup(slotsLocal[nextIndex])
  nextIndex++
  if (nextIndex === 4) {
    nextIndex = 0
  }
}

watch(
  () => selectedSlots,
  (newValue) => {
    selectedSlotsLocal.value = newValue
  }
)
</script>
