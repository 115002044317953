<i18n>
ru:
  addToCart: 'В корзину за '
  addToCartFrom: 'В корзину от '
  comboOptions: 'Состав комбо на выбор:'
  free: Бесплатно
  redo: Собрать заново
  slotNotSelected: Выберите одно блюдо из предложенного списка
ua:
  addToCart: 'В кошик за '
  addToCartFrom: 'В корзину від '
  comboOptions: 'Склад комбо на вибір:'
  free: Безкоштовно
  redo: Зібрати заново
  slotNotSelected: Виберіть одне блюдо із запропонованого списку
us:
  addToCart: 'Add to cart for '
  addToCartFrom: 'Add to cart for '
  comboOptions: 'Combo options:'
  free: Free
  redo: Reassemble
  slotNotSelected: Choose one from the suggested list
</i18n>

<template>
  <div
    v-if="slotBase && slot"
    class="v-slots"
  >
    <div
      v-if="isExtraSmall"
      class="v-slots-lunch-mobile"
    >
      <div class="v-slots-lunch-mobile-title-image">
        <arora-nuxt-image
          :key="slotBase.ID"
          :image="
            imageExists(slotBase.CombineMobileImage) ? slotBase.CombineMobileImage : slotBase.NormalImage
          "
          :alt="slotBase.Name"
          :image-type="imageExists(slotBase.CombineMobileImage) ? 'CombineMobile' : 'ProductNormal'"
        />
      </div>
      <div class="v-slots-lunch-mobile-title">
        <h1
          class="v-slots-lunch-mobile-title-main"
          itemprop="name"
          v-html="sanitize(slotBase.Name)"
        />
        <common-quantity-measure
          v-if="slotBase.Quantity > 0"
          :measure="slotBase.Measure"
          :quantity="slotBase.Quantity"
        />
        <common-quantity-measure
          v-else-if="slotBase.Weight > 0"
          :quantity="slotBase.Weight"
        />
      </div>
      <div
        class="v-menu-description v-slots-lunch-mobile-subtitle"
        v-html="sanitize(slotBase.Description)"
      />

      <arora-button
        v-for="slotVariant in slotBase.Slots.sort((s) => s.SortWeight)"
        :key="slotVariant.ID"
        class-name="v-slots-lunch-big-slot-single"
        ignore-settings
        :label="slotVariant.Name"
        data-test-id="slots-lunch-group-btn"
        @click="async () => await callForMobilePopup(slotVariant)"
      >
        <template v-if="selectedSlots[slotVariant.ID]">
          <div
            class="v-slots-lunch-big-slot-single-image"
            itemscope
            itemtype="http://schema.org/ImageObject"
          >
            <arora-nuxt-image
              :key="selectedSlots[slotVariant.ID].ID"
              :image="
                imageExists(selectedSlots[slotVariant.ID].CombineMobileImage)
                  ? selectedSlots[slotVariant.ID].CombineMobileImage
                  : selectedSlots[slotVariant.ID].NormalImage
              "
              :image-type="
                imageExists(selectedSlots[slotVariant.ID].CombineMobileImage)
                  ? 'CombineMobile'
                  : 'ProductNormal'
              "
              :alt="selectedSlots[slotVariant.ID].Name"
            />
          </div>
          <div class="v-slots-lunch-big-slot-single--text">
            <span v-html="selectedSlots[slotVariant.ID].Name" />
            <div
              v-if="!stringIsNullOrWhitespace(selectedSlots[slotVariant.ID].Description)"
              class="v-slots-lunch-big-slot-single--subtext v-menu-description"
              v-html="selectedSlots[slotVariant.ID].Description"
            />
          </div>
        </template>
        <template v-else>
          <div
            v-if="imageExists(slotVariant.Image)"
            class="v-slots-lunch-big-slot-single-image"
            itemscope
            itemtype="http://schema.org/ImageObject"
          >
            <arora-nuxt-image
              :key="slotVariant.ID"
              :alt="slotVariant.Name"
              :image="slotVariant.Image"
            />
          </div>
          <div class="v-slots-lunch-big-slot-single--text">
            <span v-html="slotVariant.Name" />
            <div
              class="v-slots-lunch-big-slot-single--subtext"
              v-html="translate('slotsLunchPage.slotNotSelected')"
            />
          </div>
        </template>
      </arora-button>

      <div
        v-if="baseProduct"
        class="v-w-100 v-d-flex v-flex-row"
        data-test-id="product-half-option"
      >
        <menu-option-selector
          :options="baseProduct.Options"
          :product="baseProduct"
          :threshold="appConfig.VueSettingsPreRun.ProductOptionThreshold"
          data-test-id="product-page-options"
        />
      </div>
      <arora-button
        class-name="v-w-100 v-btn-lg v-btn-lunch-page"
        :disabled="!slotsReady"
        :label="translate('addToCartButton.addToCart')"
        @click="async () => await addToBasket()"
      >
        <template v-if="price > 0">
          <span v-html="translate('slotsLunchPage.addToCart')" />
          <common-currency :amount="price" />
        </template>
        <span
          v-else
          v-html="translate('addToCartButton.addToCart')"
        />
      </arora-button>
    </div>
    <div
      v-else
      class="v-slots-lunch-big"
    >
      <div class="v-slots-lunch-big-variants">
        <h1
          class="v-slots-lunch-big-title"
          v-html="sanitize(slotBase.Name)"
        />
        <div class="v-slots-lunch-big-scroll-area v-scrollbar v-pm-shadow">
          <template v-if="appConfig.VueSettingsPreRun.MenuSlotsLayout === 'Erebor'">
            <common-cards-flex-mesh
              :items="slotProducts"
              :max-items="fromPopup ? 3 : 4"
            >
              <template #item="item: ProductInList">
                <div
                  class="v-slot-product-single"
                  itemscope
                  :class="{
                    'v-active': selectedSlots[slot.ID]?.ID === item.ID,
                    disabled: menuStore.StopListMap.ProductIds.includes(item.ID)
                  }"
                  data-test-id="slots-lunch-add-product"
                  itemtype="http://schema.org/ImageObject"
                  @click="() => selectSlot(slot?.ID, item)"
                >
                  <arora-nuxt-image
                    :key="item.ID"
                    :image="
                      imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage
                    "
                    :image-type="
                      imageExists(item.CombineMobileImage) ? 'CombineMobile' : 'ProductNormal'
                    "
                    :alt="item.Name"
                  />
                  <div
                    class="v-slot-product-single-title"
                    v-html="sanitize(item.Name)"
                  />
                  <div
                    class="v-slot-product-single--subtitle v-d-flex v-justify-content-center v-align-items-center"
                  >
                    <template v-if="slotProducts.some((sp) => sp.Price > 0)">
                      <common-currency
                        v-if="item.Price > 0"
                        :amount="item.Price"
                      />
                      <span
                        v-else
                        v-html="translate('slotsLunchPage.free')"
                      />
                    </template>
                    <common-quantity-measure
                      v-else-if="item.Quantity > 0"
                      :measure="item.Measure"
                      :quantity="item.Quantity"
                    />
                    <common-quantity-measure
                      v-else-if="item.Weight > 0"
                      :quantity="item.Weight"
                    />
                  </div>
                </div>
              </template>
            </common-cards-flex-mesh>
          </template>
          <template v-else-if="appConfig.VueSettingsPreRun.MenuSlotsLayout === 'Gladden'">
            <common-cards-flex-mesh
              :items="slotProducts"
              :max-items="fromPopup ? 3 : 4"
            >
              <template #item="item: ProductInList">
                <div
                  class="v-slot-product-single"
                  itemscope
                  :class="{
                    disabled: menuStore.StopListMap.ProductIds.includes(item.ID)
                  }"
                  data-test-id="slots-lunch-add-product"
                  itemtype="http://schema.org/ImageObject"
                  @click="() => selectSlot(slot?.ID, item)"
                >
                  <arora-nuxt-image
                    :key="item.ID"
                    :image="
                      imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage
                    "
                    :image-type="
                      imageExists(item.CombineMobileImage) ? 'CombineMobile' : 'ProductNormal'
                    "
                    :alt="item.Name"
                  />
                  <div
                    class="v-slot-product-single-title"
                    v-html="sanitize(item.Name)"
                  />
                  <div
                    v-if="!stringIsNullOrWhitespace(item.Description)"
                    class="v-slot-product-single-description v-menu-description"
                    v-html="sanitize(item.Description)"
                  />
                  <div class="v-slot-product-single-under-description">
                    <common-currency
                      v-if="slotProducts.some((sp) => sp.Price > 0)"
                      :amount="item.Price"
                    />
                    <common-quantity-measure
                      v-else-if="item.Quantity > 0"
                      :measure="item.Measure"
                      :quantity="item.Quantity"
                    />
                    <common-quantity-measure
                      v-else-if="item.Weight > 0"
                      :quantity="item.Weight"
                    />
                    <div class="v-slot-product-single-under-description-button">
                      <i
                        class="fas"
                        :class="[
                          selectedSlots[slot.ID]?.ID === item.ID
                            ? 'v-active fa-check-circle'
                            : 'fa-plus-circle'
                        ]"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </common-cards-flex-mesh>
          </template>
        </div>
      </div>
      <div class="v-slots-lunch-big--confirm">
        <div class="v-slots-lunch-big-slots v-pm-shadow v-scrollbar">
          <div
            class="v-slots-lunch-big-slots-title"
            v-html="translate('slotsLunchPage.comboOptions')"
          />
          <div class="v-slots-lunch-big-slots-desc">
            <menu-description
              is-popup-or-full-page
              :product-in-list="slotBase"
            />
          </div>
          <arora-button
            v-for="slotVariant in slotBase.Slots.sort((s) => s.SortWeight)"
            :key="slotVariant.ID"
            class-name="v-slots-lunch-big-slot-single"
            ignore-settings
            :label="slotVariant.Name"
            data-test-id="slots-lunch-group-btn"
            @click="() => selectSlotGroup(slotVariant)"
          >
            <template v-if="selectedSlots[slotVariant.ID]">
              <div
                class="v-slots-lunch-big-slot-single-image"
                itemscope
                itemtype="http://schema.org/ImageObject"
              >
                <arora-nuxt-image
                  v-if="imageExists(selectedSlots[slotVariant.ID].ProductMobileImage)"
                  :key="`ProductMobileImage-${selectedSlots[slotVariant.ID].ID}`"
                  :alt="slotVariant.Name"
                  :image="selectedSlots[slotVariant.ID].ProductMobileImage"
                  image-type="ProductMobile"
                />
                <arora-nuxt-image
                  v-else-if="imageExists(selectedSlots[slotVariant.ID].CombineMobileImage)"
                  :key="`CombineMobileImage-${selectedSlots[slotVariant.ID].ID}`"
                  :alt="slotVariant.Name"
                  :image="selectedSlots[slotVariant.ID].CombineMobileImage"
                  image-type="CombineMobile"
                />
                <arora-nuxt-image
                  v-else-if="imageExists(selectedSlots[slotVariant.ID].NormalImage)"
                  :key="`NormalImage-${selectedSlots[slotVariant.ID].ID}`"
                  :alt="slotVariant.Name"
                  :image="selectedSlots[slotVariant.ID].NormalImage"
                  image-type="ProductNormal"
                />
              </div>
              <div class="v-slots-lunch-big-slot-single--text">
                <span v-html="selectedSlots[slotVariant.ID].Name" />
                <div
                  v-if="!stringIsNullOrWhitespace(selectedSlots[slotVariant.ID].Description)"
                  class="v-slots-lunch-big-slot-single--subtext v-menu-description"
                  itemprop="description"
                  v-html="selectedSlots[slotVariant.ID].Description"
                />
              </div>
            </template>
            <template v-else>
              <div
                v-if="imageExists(slotVariant.Image)"
                class="v-slots-lunch-big-slot-single-image"
                itemscope
                itemtype="http://schema.org/ImageObject"
              >
                <arora-nuxt-image
                  :alt="slotVariant.Name"
                  :image="slotVariant.Image"
                />
              </div>
              <div class="v-slots-lunch-big-slot-single--text">
                <span v-html="slotVariant.Name" />
                <div
                  class="v-slots-lunch-big-slot-single--subtext"
                  v-html="translate('slotsLunchPage.slotNotSelected')"
                />
              </div>
            </template>
          </arora-button>
        </div>

        <div
          v-if="
            !stringIsNullOrWhitespace(slotBase.NoOrderTypeReasonText) ||
            !stringIsNullOrWhitespace(slotBase.PersonalProductMessage)
          "
          class="v-slots-lunch-big-selection-slot-message"
        >
          <icon-menu-info class="v-mr-xs" />
          <div class="v-d-flex v-flex-column v-justify-content-center">
            <span v-html="sanitize(slotBase.NoOrderTypeReasonText)" />
            <span v-html="sanitize(slotBase.PersonalProductMessage)" />
          </div>
        </div>

        <div class="v-slots-half-big-selection-button--confirm">
          <div
            v-if="baseProduct"
            class="v-w-100 v-d-flex v-flex-row v-mb-sm"
            data-test-id="product-half-option"
          >
            <menu-option-selector
              :options="baseProduct.Options"
              :product="baseProduct"
              :threshold="appConfig.VueSettingsPreRun.ProductOptionThreshold"
              data-test-id="product-page-options"
            />
          </div>
          <div
            v-if="isProductUnavailable(slotBase)"
            class="v-flex-100 v-d-flex v-flex-row"
          >
            <div
              v-if="menuStore.StopListMap.ProductIds.includes(slotBase.ID)"
              class="v-nomenclature-card-in-stop-list v-product-in-stop-list v-btn-lg"
              data-test-id="product-in-stop-list"
            >
              <span v-html="translate('productCard.inStopListButton')" />
            </div>
            <common-popover
              v-else
              class="v-w-100"
            >
              <template #hoverable>
                <div
                  class="v-nomenclature-card-in-stop-list v-product-in-stop-list"
                  data-test-id="slot-half-card-unavailable-by-order-type"
                >
                  <span v-html="translate('productCard.notAvailableByOrderTypeButton')" />
                </div>
              </template>
              <template #content>
                <span
                  v-html="
                    clientStore.orderTypeNotSelected
                      ? translate('productCard.notAvailableByOrderType')
                      : translate('productCard.notAvailableByOrderTypeTooltip', {
                          orderType: translate(clientStore.orderTypeText)
                        })
                  "
                />
              </template>
            </common-popover>
          </div>
          <arora-button
            v-else
            class-name="v-w-100"
            :disabled="!slotsReady"
            :label="translate('addToCartButton.addToCart')"
            data-test-id="slots-lunch-buy"
            @click="async () => await addToBasket()"
          >
            <template v-if="price > 0">
              <span
                v-html="
                  translate(slotsReady ? 'slotsLunchPage.addToCart' : 'slotsLunchPage.addToCartFrom')
                "
              />
              <common-currency :amount="price" />
            </template>
            <span
              v-else
              v-html="translate('addToCartButton.addToCart')"
            />
          </arora-button>
        </div>
      </div>
    </div>
  </div>
  <common-skeleton v-else />
</template>

<script setup lang="ts">
import { FirstAddType } from '~api/consts'

import type { CartSlot } from '~types/clientStore'
import type { ProductInList, Slot, SlotBase } from '~types/menuStore'
import type { SlotsCommon } from '~types/props'

import { type GUID, useCommon, useWindowSize } from '@arora/common'

const { slotId, isRecommendation } = defineProps<SlotsCommon>()

const clientStore = useClientStore()
const menuStore = useMenuStore()
const popupStore = usePopupStore()

const { translate, sanitize } = useI18nSanitized()
const { isExtraSmall } = useWindowSize()
const { objectKeys, stringIsNullOrWhitespace } = useCommon()
const { imageExists } = useImageInfo()
const { fromPopup } = useInstance()
const { addToCartWithPayload } = useCartFunctionsWithDialogs()
const { isProductUnavailable, viewProduct } = useProduct()
const appConfig = useAppConfig()

const slotBase = ref<SlotBase | undefined>()
const slot = ref<Slot | null>(null)
const baseProduct = ref<ProductInList | undefined>()

const slotProducts = computed<ProductInList[]>(() =>
  appConfig.VueSettingsPreRun.MenuPageHideStopList
    ? (slot.value?.Products ?? []).filter(
        (product) => !menuStore.StopListMap.ProductIds.includes(product.ID)
      )
    : (slot.value?.Products ?? [])
)

async function clearSlots(): Promise<void> {
  selectedSlots.value = {}
  slot.value = slotBase.value?.Slots[0] ?? null
}

const { makeDefaultOption } = useProduct()

onMounted(async () => {
  baseProduct.value = appConfig.Products.find((product) => product.ID === slotId)
  const optionFromStore = menuStore.SelectedOptionsPerProduct.get(slotId)

  slotBase.value = appConfig.Slots.find(
    (slot) => slot.ID === (optionFromStore ? optionFromStore.ID : slotId)
  )

  if (slotBase.value) {
    slot.value = slotBase.value.Slots[0]
    makeDefaultOption(slotBase.value)

    viewProduct(slotBase.value, price.value)
  }
})

watch(
  () => menuStore.SelectedOptionsPerProduct.get(slotId)?.ID,
  async (value, oldValue) => {
    await clearSlots()
    if (value && value !== oldValue && oldValue !== undefined) {
      slotBase.value = appConfig.Slots.find((slot) => slot.ID === value)
      if (slotBase.value) {
        slot.value = slotBase.value.Slots[0]
      }
    }
  }
)
const price = computed<number>(() => {
  if (!slotBase.value) return 0

  let price = slotBase.value.Price > 0 ? slotBase.value.Price : (baseProduct.value?.Price ?? 0)

  for (const key of objectKeys(selectedSlots.value)) {
    price += selectedSlots.value[key]?.Price ?? 0
  }

  return price
})
const slotsReady = computed<boolean>(() => {
  const requiredSlotsIDs: GUID[] = (slotBase.value?.Slots ?? [])
    .filter((s) => s.IsRequired)
    .map((item) => item.ID)

  return (
    requiredSlotsIDs.length > 0 &&
    requiredSlotsIDs.every((item) => objectKeys(selectedSlots.value).includes(item))
  )
})

const selectedSlots = ref<Record<GUID, ProductInList>>({})

function selectSlotGroup(newSlot: Slot): void {
  slot.value = newSlot
}

function selectSlot(slotId: GUID | undefined, item: ProductInList): void {
  if (!slotBase.value || !slotId) return

  selectedSlots.value[slotId] = item

  let foundPreviousId = false
  for (const slotLocal of (slotBase.value?.Slots ?? []).sort((s) => s.SortWeight)) {
    if (foundPreviousId) {
      selectSlotGroup(slotLocal)

      return
    }
    if (slotLocal.ID === slotId) foundPreviousId = true
  }
}

async function callForMobilePopup(newSlot: Slot): Promise<void> {
  await popupStore.openPopup({
    popupName: 'menuSlotsLunchMobilePopup',
    popupClosable: true,
    popupProperties: new Map<string, unknown>([
      ['selectedSlotId', selectedSlots.value[newSlot.ID]?.ID],
      ['selectSlot', selectSlot],
      ['slot', newSlot]
    ])
  })
}

async function addToBasket(): Promise<void> {
  if (!slotBase.value || !slot.value) return

  const slotsForSend: CartSlot[] = []

  for (const key of objectKeys(selectedSlots.value)) {
    const slotByKey = slotBase.value.Slots.find((s) => s.ID === key)

    if (slotByKey)
      slotsForSend.push({
        ID: slotByKey.ID,
        IsRequired: slotByKey.IsRequired,
        Modifiers: {},
        ProductID: selectedSlots.value[key].ID
      })
  }

  const added = await addToCartWithPayload({
    product: {
      productID: slotId,
      optionID: menuStore.SelectedOptionsPerProduct.get(slotId)?.ID,
      count: 1,
      groupId: slotBase.value.GroupID,
      name: slotBase.value.Name,
      priceModified: price.value
    },
    slots: slotsForSend,
    firstAddType: isRecommendation ? FirstAddType.Recommendation : FirstAddType.Default
  })

  if (added && fromPopup.value) {
    popupStore.closePopup()
  }
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-slot-product-single {
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
  height: 100%;

  &.v-active {
    border: 1.5px solid variables.$PrimaryBackgroundColor;
    border-radius: variables.$BorderRadius;
  }

  &-title {
    text-align: center;
    margin: 1rem 0;
  }

  &-description {
    text-align: left;
    margin: 15px;
    color: variables.$BodyTextColorLight;

    flex: 1 0 auto;
  }

  &-under-description {
    display: flex;
    justify-content: space-between;
    margin: 0 15px 24px;

    &-button {
      i {
        font-size: 24px;
        color: variables.$BodyTextColorLight;

        &.v-active {
          color: variables.$PrimaryBackgroundColor;
        }
      }
    }
  }

  &--subtitle {
    text-align: center;
    margin-bottom: 1.1rem;
    color: variables.$BodyTextColorLight;
  }

  .v-img-fluid {
    border-radius: variables.$BorderRadius;
  }
}

.v-slots-lunch-big {
  display: flex;

  min-height: 500px;
  gap: 10px;

  .v-slots-lunch-big-title {
    font-size: variables.$TextSizeH3;
    font-weight: 600;
    margin-bottom: 16px;
  }

  &-variants {
    flex: 0 0 calc(65% - 10px);
    max-width: calc(65% - 10px);
  }

  &-scroll-area {
    max-height: 600px;
    overflow-y: scroll;
  }

  &-slots {
    margin-bottom: 10px;
    max-height: 600px;
    overflow-y: scroll;

    &-desc {
      font-size: variables.$TextSizeMain;
      margin-bottom: 10px;
      color: variables.$BodyTextColorLight;
    }

    &-title {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }
  }

  &--confirm {
    flex: 0 0 35%;
    max-width: 35%;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .v-btn {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      gap: 3px;
    }
  }
}

.v-slots-lunch-big-slot-single {
  width: 100%;
  min-height: 100px;

  background-color: variables.$BodyElementsBackground;
  color: variables.$BodyTextColor;
  cursor: pointer;
  box-shadow: variables.$CardShadow;
  border-radius: variables.$BorderRadius;
  margin-bottom: 1.1rem;
  padding: 15px;

  outline: none;
  border: none;
  font-size: variables.$TextSizeMain;
  text-decoration: none;
  @include mixins.trans;

  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  &-image {
    flex: 0 0 calc(25% - 10px);
    max-width: calc(25% - 10px);
    height: auto;
  }

  &--text {
    text-align: left;
    flex: 0 0 75%;
    max-width: 75%;
  }

  &--subtext {
    color: variables.$BodyTextColorLight;
  }
}

.v-slots-lunch-big-selection-slot-message {
  display: flex;
  width: 100%;
  color: variables.$BodyTextColorLight;
  font-size: variables.$TextSizeLabel;
  padding: 10px 0;
  margin-top: auto;

  svg {
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
  }
}

.v-slots-lunch-mobile {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .v-slots-lunch-mobile-title-image {
    flex: 0 0 100%;
    max-width: 100%;
    border-radius: variables.$BorderRadius;
    overflow: hidden;
  }

  .v-slots-lunch-mobile-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .v-slots-lunch-mobile-title-main {
      font-size: variables.$TextSizeH3;
      font-weight: 600;
    }
  }

  .v-slots-lunch-mobile-subtitle {
    color: variables.$BodyTextColorLight;
  }
  .v-btn-lunch-page {
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
