<i18n>
ru:
  addModifierProduct: Добавить ингредиент
  comboProductSaving: Экономия
  inStopListButton: Распродано
  notAvailableByOrderType: >-
    Недоступно при некоторых способах получения заказа. Сначала выберите тип
    заказа
  notAvailableByOrderTypeButton: Недоступно
  notAvailableByOrderTypeTooltip: >-
    Выберите тип заказа отличный от "{orderType}", если хотите заказать это
    блюдо
  priceWithoutCombo: Сумма без комбо
  showTextInPriceButton: от
ua:
  addModifierProduct: Додати інгредієнт
  comboProductSaving: Економія
  inStopListButton: Розпродано
  notAvailableByOrderType: >-
    Недоступно при деяких способах отримання замовлення. Спочатку виберіть тип
    замовлення
  notAvailableByOrderTypeButton: Недоступно
  notAvailableByOrderTypeTooltip: >-
    Виберіть тип замовлення, відмінний від "{orderType}", якщо хочете замовити
    це блюдо
  priceWithoutCombo: Сума без комбо
  showTextInPriceButton: від
us:
  addModifierProduct: Add an ingredient
  comboProductSaving: Savings
  inStopListButton: Sold out
  notAvailableByOrderType: 'Not available for some order type methods. First, select the order type'
  notAvailableByOrderTypeButton: Not available
  notAvailableByOrderTypeTooltip: >-
    Choose a different order type from "{orderType}" if you want to order this
    dish
  priceWithoutCombo: Amount without combo
  showTextInPriceButton: from
</i18n>

<template>
  <div
    v-if="appConfig.VueSettingsPreRun.MenuItemLayout === 'Gondor'"
    class="Gondor v-nomenclature-card v-product-card"
    :class="{
      'v-horizontal-mobile-card': isSmall && imageExists(productInList.ProductMobileImage)
    }"
    data-test-id="product-card"
  >
    <div
      :key="productInList.ID"
      class="v-menu-item-image v-pointer v-mb-xs"
      itemscope
      data-test-id="product-card-item-image"
      itemtype="http://schema.org/ImageObject"
      @click="async () => await cardClick()"
    >
      <menu-stickers :stickers="productInList.StickerTags" />
      <arora-nuxt-image
        :image="
          isSmall && imageExists(productInList.ProductMobileImage)
            ? productInList.ProductMobileImage
            : productInList.SmallImage
        "
        :image-type="
          isSmall && imageExists(productInList.ProductMobileImage) ? 'ProductMobile' : 'ProductSmall'
        "
        :alt="productInList.Name"
        :disable-lazy="disableLazy"
        itemprop="image"
      />
      <div
        v-if="accountStore.isLoggedIn"
        class="v-product-card-favorite"
      >
        <menu-product-favorite-icon
          :product-id="productInList.ID"
          @click.stop="async () => await accountStore.addOrRemoveFromFavorites(productInList)"
        />
      </div>
    </div>

    <div class="v-nomenclature-card-summary v-product-card-summary">
      <div class="v-nomenclature-card-summary-top v-product-card-summary-top">
        <div class="v-title-wrapper v-mb-xs">
          <div
            class="v-nomenclature-card-title v-product-card-title v-info-nomenclature v-pointer"
            :class="{ 'v-no-kbzu': !hasCaloric(caloricValue) }"
          >
            <common-popover
              v-if="
                !stringIsNullOrWhitespace(
                  productInList.NoOrderTypeReasonText || productInList.PersonalProductMessage
                )
              "
              class="v-info-nomenclature v-pointer v-mr-xs"
            >
              <template #hoverable>
                <span> <icon-menu-info /></span>
              </template>
              <template #content>
                <div v-html="sanitize(productInList.NoOrderTypeReasonText)" />
                <div v-html="sanitize(productInList.PersonalProductMessage)" />
              </template>
            </common-popover>

            <arora-nuxt-link
              class-name="v-product-card-title-link v-title"
              :disabled="allOptionsInStopList"
              :href="productInList.Link"
              :label="sanitize(productInList.Name)"
              itemprop="url"
            />
          </div>
          <div
            v-if="hasCaloric(caloricValue)"
            class="v-kbzu-short-wrapper"
          >
            <common-popover class="kbzu">
              <template #hoverable>
                <div
                  class="v-kbzu-short"
                  v-html="translate('caloric.kbzyShort')"
                />
              </template>
              <template #content>
                <menu-caloric
                  :caloric-product="caloricValue"
                  :shorter-names="false"
                />
              </template>
            </common-popover>
          </div>
        </div>
        <menu-description :product-in-list="productInList" />
      </div>
      <div class="v-product-card-summary-bottom">
        <div
          v-if="objectKeys(productInList.Options).length > 0"
          class="v-option"
          data-test-id="product-option"
        >
          <menu-option-selector
            :options="productInList.Options"
            :product="productInList"
            :threshold="appConfig.VueSettingsPreRun.MenuItemsOptionThreshold"
            data-test-id="product-card-options"
            theme="product-card"
          />
        </div>
        <div
          v-if="productInList.ModifierGroups.length > 0"
          class="v-has-modifiers v-mb-sm"
        >
          <icon-general-plus class="v-mr-xs" />
          <arora-button
            class-name="v-btn-text v-no-underline"
            ignore-settings
            :label="translate('productCard.addModifierProduct')"
            data-test-id="product-card-add-modifier"
            @click="async () => await cardClick()"
          />
        </div>
        <menu-card-available-by-order-type-text :product-in-list="productInList" />
        <div
          v-if="isProductUnavailable(productInList)"
          class="v-flex-100 v-d-flex v-flex-row"
        >
          <div
            v-if="menuStore.StopListMap.ProductIds.includes(productInList.ID)"
            class="v-nomenclature-card-in-stop-list v-product-in-stop-list"
            data-test-id="product-card-in-stop-list"
          >
            <span v-html="translate('productCard.inStopListButton')" />
          </div>
          <common-popover
            v-else
            class="v-w-100"
          >
            <template #hoverable>
              <div
                class="v-nomenclature-card-in-stop-list v-product-in-stop-list"
                data-test-id="product-card-unavailable-by-order-type"
              >
                <span v-html="translate('productCard.notAvailableByOrderTypeButton')" />
              </div>
            </template>
            <template #content>
              <span
                v-html="
                  clientStore.orderTypeNotSelected
                    ? translate('productCard.notAvailableByOrderType')
                    : translate('productCard.notAvailableByOrderTypeTooltip', {
                        orderType: translate(clientStore.orderTypeText)
                      })
                "
              />
            </template>
          </common-popover>
        </div>
        <div
          v-else
          class="v-flex-100 v-d-flex v-flex-row-no-wrap v-justify-content-between v-align-items-center"
        >
          <menu-product-in-list-price-and-button
            :card-click="cardClick"
            :product-in-list="productInList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { HaveCaloricValues, Measurable } from '~types/common'
import type { Option } from '~types/menuStore'
import type { AnyProductCard } from '~types/props'

import { useCommon, useWindowSize } from '@arora/common'

const { productInList } = defineProps<
  AnyProductCard & {
    disableLazy: boolean
  }
>()

const accountStore = useAccountStore()
const clientStore = useClientStore()
const menuStore = useMenuStore()

const { stringIsNullOrWhitespace, objectKeys } = useCommon()
const { imageExists } = useImageInfo()
const { isSmall } = useWindowSize()
const { isProductUnavailable, hasCaloric, getSameProductsInCart, setProductModifiersToZero } =
  useProduct()

const { sanitize, translate } = useI18nSanitized()
const appConfig = useAppConfig()
const caloricValue = computed<HaveCaloricValues & Measurable>(() => {
  const optionInStore = menuStore.SelectedOptionsPerProduct.get(productInList.ID)

  if (productInList.UseOptionsDescription && optionInStore && hasCaloric(optionInStore))
    return optionInStore

  return productInList
})

onMounted(() => {
  const productInCart = getSameProductsInCart(productInList.ID)[0]
  if (productInCart) {
    let mods = menuStore.SelectedModifiersPerProduct.get(productInList.ID)

    if (!mods) mods = {}

    for (const mod of productInCart.Modifiers) {
      mods[mod.ID] = mod.Count
    }

    menuStore.SelectedModifiersPerProduct.set(productInList.ID, mods)

    setProductModifiersToZero(
      productInList.ID,
      productInCart.Modifiers.map((mod) => mod.ID)
    )
  }
})

const allOptionsInStopList = computed<boolean>(() => {
  const options = ([] as Option[]).concat.apply([], Object.values(productInList.Options))

  return (
    menuStore.StopListMap.ProductIds.includes(productInList.ID) ||
    (options.length > 0 &&
      options.every((option) => menuStore.StopListMap.OptionIds.includes(option.ID)))
  )
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-product-card {
  .v-product-card-summary {
    .v-title-wrapper {
      .v-product-card-title {
        flex: 0 0 80%;
        max-width: 80%;
      }

      .v-kbzu-short-wrapper {
        display: flex;
        flex: 0 1 auto;
        width: 100%;
        justify-content: flex-end;
      }

      .v-product-card-title-link {
        color: variables.$ProductCardColor;
        font-weight: 600;
        font-size: 1.25rem;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .v-product-card-summary-bottom {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    .v-stepper-counter-horizontal,
    .v-btn {
      flex: 0 1 auto;
      width: auto;
    }

    .v-product-in-points-btn .v-btn {
      flex: 0 1 100%;
      width: 100%;
    }

    .v-option,
    .v-card-unavailable-message,
    .v-has-modifiers {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: left;
    }

    .v-has-modifiers {
      color: variables.$ProductCardColorBright;
      font-size: 1rem;

      button {
        color: variables.$ProductCardColorBright;
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }
}

.v-product-in-stop-list {
  //todo: цвет стоплиста
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 8px 16px;
  background: rgba(0, 0, 21, 0.3);
  color: #fff;
  border-radius: variables.$BorderRadius;
}
</style>
