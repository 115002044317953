<i18n>
ru:
  cancel: Отменить
  notAllFilled: Не все поля заполнены или заполнены неправильно
  title: Добавить адрес доставки
ua:
  cancel: Скасувати
  notAllFilled: Не всі поля заповнені або заповнені неправильно
  title: Додати адресу доставки
us:
  cancel: Cancel
  notAllFilled: Not all fields are filled or filled incorrectly
  title: Add delivery address
</i18n>

<template>
  <div class="v-row v-mb-sm">
    <div
      class="v-popup-head v-title v-col-12 v-saved-address-popup-title"
      v-html="translate('yandexMapsSavedAddressesPopup.title')"
    />
  </div>
  <div
    v-if="restaurantData && restaurantData.length > 1"
    class="v-row v-mb-sm v-align-items-center"
  >
    <div class="v-col-7 v-col-md-9">
      <arora-select
        :label="translate('addressSelectorPopup.yourCity')"
        :options="restaurantData.map((item) => item.ID)"
        data-test-id="city-select"
        v-model:selected="selectedCity"
      >
        <template #index="options: { index: number }">
          <option
            :value="restaurantData[options.index].ID"
            v-html="clean(restaurantData[options.index].Name)"
          />
        </template>
      </arora-select>
    </div>
  </div>
  <div class="v-row v-mb-sm">
    <div
      class="v-col-12 v-font-weight-600"
      v-html="translate('addressSelectorPopup.yourAddress')"
    />
  </div>

  <div
    class="v-mb-sm"
    :class="[
      appConfig.VueSettingsPreRun.CorpusHidden
        ? 'v-saved-address-wrapper--no_corpus'
        : 'v-saved-address-wrapper'
    ]"
  >
    <div class="v-saved-address-wrapper__street">
      <common-typeahead-input
        required
        v-form-validator="{
          Form: validatorForm,
          Value: isUserStreetCorrect ? '#' : '',
          Required: true,
          RequiredText: translate('yandexMapsAutocomplete.incorrectStreet'),
          Validations: ['required', 'lengthMinMax'],
          MinLength: 1,
          MaxLength: 10
        }"
        :input="userStreet"
        :label="translate('yandexMapsAutocomplete.street')"
        :min-chars="appConfig.VueSettingsPreRun.StreetListAutocompleteMinChars"
        :user-input-changed="userInputStreetChanged"
        :zero-results="translate('yandexMapsAutocomplete.incorrectStreet')"
      />
      <div
        v-if="!isUserStreetGeocoded"
        class="v-error-color v-mb-sm"
        v-html="translate('yandexMapsAutocomplete.incorrectGeocode')"
      />
    </div>
    <div class="v-saved-address-wrapper__house">
      <common-typeahead-input
        disable-subtitle
        required
        v-form-validator="{
          Form: validatorForm,
          Value: isUserHouseCorrect ? '#' : '',
          Required: true,
          RequiredText: translate('yandexMapsAutocomplete.incorrectHouse'),
          Validations: ['required', 'lengthMinMax'],
          MinLength: 1,
          MaxLength: 10
        }"
        :disabled="stringIsNullOrWhitespace(userStreet)"
        :input="userHouse"
        :label="translate('yandexMapsAutocomplete.house')"
        :min-chars="0"
        :user-input-changed="userInputHouseChanged"
        :zero-results="translate('yandexMapsAutocomplete.incorrectHouse')"
      />
    </div>
    <div
      v-if="!appConfig.VueSettingsPreRun.CorpusHidden"
      class="v-saved-address-wrapper__corpus"
    >
      <v-input
        class-name="v-arora-input"
        v-form-validator="{
          Form: validatorForm,
          Value: newAddress.Corpus,
          Required: appConfig.VueSettingsPreRun.CorpusRequired,
          Validations: ['noSpace', 'length'],
          MaxLength: 10
        }"
        :disabled="stringIsNullOrWhitespace(userStreet)"
        :label="translate('yandexMapsAutocomplete.corpus')"
        :required="appConfig.VueSettingsPreRun.CorpusRequired"
        v-model:text="newAddress.Corpus"
      />
    </div>
    <div
      v-if="isSmall"
      class="v-w-100 v-saved-address-wrapper__additional"
    >
      <div
        v-if="orderDeliveryTypes.length > 0"
        class="v-row v-mb-sm"
      >
        <div class="v-col-12">
          <div
            class="v-additional-order-delivery-type v-mb-sm"
            v-form-validator="{
              Form: validatorForm,
              Value: newAddress.OrderDeliveryType <= 0 ? null : newAddress.OrderDeliveryType,
              Required: true,
              Validations: []
            }"
            data-test-id="additional-order-delivery-type"
          >
            <arora-radio-input
              as-column
              hide-label
              required
              :label="translate('additionalInfo.orderDeliveryTypes')"
              :options="orderDeliveryTypes"
              v-model:selected="newAddress.OrderDeliveryType"
            >
              <template #option="option">
                <span
                  v-if="option.value === OrderDeliveryType.Flat"
                  data-test-id="order-user-delivery-type-flat"
                  v-html="translate('additionalInfo.orderTypeFlat')"
                />
                <span
                  v-else-if="option.value === OrderDeliveryType.House"
                  data-test-id="order-user-delivery-type-house"
                  v-html="translate('additionalInfo.orderTypeHouse')"
                />
                <span
                  v-else-if="option.value === OrderDeliveryType.Org"
                  data-test-id="order-user-delivery-type-org"
                  v-html="translate('additionalInfo.orderTypeOrg')"
                />
              </template>
            </arora-radio-input>
          </div>
        </div>
      </div>

      <transition
        appear
        mode="out-in"
        name="slidedown"
      >
        <div
          v-if="newAddress.OrderDeliveryType === OrderDeliveryType.Flat"
          class="v-row v-mb-sm"
          data-test-id="additional-order-delivery-type-flat"
        >
          <div class="v-col-6">
            <v-input
              class-name="v-arora-input"
              required
              v-form-validator="{
                Form: validatorForm,
                Value: newAddress.Apartment,
                Required: true,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.apartment')"
              data-test-id="order-user-apartment"
              v-model:text="newAddress.Apartment"
            />
          </div>
          <div class="v-col-6">
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: validatorForm,
                Value: newAddress.Doorway,
                Required: false,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.doorway')"
              data-test-id="order-user-doorway"
              v-model:text="newAddress.Doorway"
            />
          </div>
          <div class="v-col-6">
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: validatorForm,
                Value: newAddress.Floor,
                Required: false,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.floor')"
              data-test-id="order-user-floor"
              v-model:text="newAddress.Floor"
            />
          </div>
          <div class="v-col-6">
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: validatorForm,
                Value: newAddress.Intercom,
                Required: false,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.intercom')"
              data-test-id="order-user-intercom"
              v-model:text="newAddress.Intercom"
            />
          </div>
        </div>
        <div
          v-else-if="newAddress.OrderDeliveryType === OrderDeliveryType.Org"
          class="v-row v-mb-sm"
          data-test-id="additional-order-delivery-type-org"
        >
          <div class="v-col-6">
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: validatorForm,
                Value: newAddress.Apartment,
                Required: false,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.office')"
              data-test-id="order-user-office"
              v-model:text="newAddress.Apartment"
            />
          </div>
          <div class="v-col-6">
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: validatorForm,
                Value: newAddress.Doorway,
                Required: false,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.doorway')"
              data-test-id="order-user-doorway"
              v-model:text="newAddress.Doorway"
            />
          </div>
          <div class="v-col-6">
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: validatorForm,
                Value: newAddress.Floor,
                Required: false,
                Validations: ['digits', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.floor')"
              data-test-id="order-user-floor"
              v-model:text="newAddress.Floor"
            />
          </div>
          <div class="v-col-6">
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: validatorForm,
                Value: newAddress.Intercom,
                Required: false,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.intercom')"
              data-test-id="order-user-intercom"
              v-model:text="newAddress.Intercom"
            />
          </div>
        </div>
      </transition>

      <div
        v-if="orderDeliveryTypes.length === 0"
        class="v-row v-mb-sm"
      >
        <div
          v-if="!appConfig.VueSettingsPreRun.DoorwayHidden"
          class="v-col-6"
        >
          <v-input
            class-name="v-arora-input"
            v-form-validator="{
              Form: validatorForm,
              Value: newAddress.Doorway,
              Required: appConfig.VueSettingsPreRun.DoorwayRequired,
              Validations: ['noSpace', 'length'],
              MaxLength: 10
            }"
            :label="translate('additionalInfo.doorway')"
            :required="appConfig.VueSettingsPreRun.DoorwayRequired"
            v-model:text="newAddress.Doorway"
          />
        </div>
        <div
          v-if="!appConfig.VueSettingsPreRun.BuildingHidden"
          class="v-col-6"
        >
          <v-input
            class-name="v-arora-input"
            v-form-validator="{
              Form: validatorForm,
              Value: newAddress.Building,
              Required: appConfig.VueSettingsPreRun.BuildingRequired,
              Validations: ['noSpace', 'length'],
              MaxLength: 10
            }"
            :label="translate('additionalInfo.building')"
            :required="appConfig.VueSettingsPreRun.BuildingRequired"
            v-model:text="newAddress.Building"
          />
        </div>
        <div
          v-if="!appConfig.VueSettingsPreRun.ApartmentHidden"
          class="v-col-6"
        >
          <v-input
            class-name="v-arora-input"
            v-form-validator="{
              Form: validatorForm,
              Value: newAddress.Apartment,
              Required: appConfig.VueSettingsPreRun.ApartmentRequired,
              Validations: ['noSpace', 'length'],
              MaxLength: 10
            }"
            :label="translate('additionalInfo.apartment')"
            :required="appConfig.VueSettingsPreRun.ApartmentRequired"
            v-model:text="newAddress.Apartment"
          />
        </div>
        <div
          v-if="!appConfig.VueSettingsPreRun.IntercomHidden"
          class="v-col-6"
        >
          <v-input
            class-name="v-arora-input"
            v-form-validator="{
              Form: validatorForm,
              Value: newAddress.Intercom,
              Required: appConfig.VueSettingsPreRun.IntercomRequired,
              Validations: ['noSpace', 'length'],
              MaxLength: 10
            }"
            :label="translate('additionalInfo.intercom')"
            :required="appConfig.VueSettingsPreRun.IntercomRequired"
            v-model:text="newAddress.Intercom"
          />
        </div>
        <div
          v-if="!appConfig.VueSettingsPreRun.FloorHidden"
          class="v-col-6"
        >
          <v-input
            class-name="v-arora-input"
            v-form-validator="{
              Form: validatorForm,
              Value: newAddress.Floor,
              Required: appConfig.VueSettingsPreRun.FloorRequired,
              Validations: ['length'],
              MaxLength: 10
            }"
            :label="translate('additionalInfo.floor')"
            :required="appConfig.VueSettingsPreRun.FloorRequired"
            v-model:text="newAddress.Floor"
          />
        </div>
      </div>

      <div class="v-row">
        <div class="v-col-12">
          <v-input
            class-name="v-arora-input"
            v-form-validator="{
              Form: validatorForm,
              Value: newAddress.Comment,
              Required: false,
              Validations: ['length'],
              MaxLength: 255
            }"
            :label="translate('comment.comment')"
            v-model:text="newAddress.Comment"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="v-saved-address-wrapper__additional v-mb-sm"
    >
      <arora-summary-details :title="translate('addressSelectorPopup.additionalInfo')">
        <div class="v-row">
          <div
            v-if="orderDeliveryTypes.length > 0"
            class="v-col-12"
          >
            <div
              class="v-additional-order-delivery-type v-mb-sm"
              v-form-validator="{
                Form: validatorForm,
                Value: newAddress.OrderDeliveryType <= 0 ? null : newAddress.OrderDeliveryType,
                Required: true,
                Validations: []
              }"
              data-test-id="additional-order-delivery-type"
            >
              <arora-radio-input
                hide-label
                required
                :label="translate('additionalInfo.orderDeliveryTypes')"
                :options="orderDeliveryTypes"
                v-model:selected="newAddress.OrderDeliveryType"
              >
                <template #option="option">
                  <span
                    v-if="option.value === OrderDeliveryType.Flat"
                    data-test-id="order-user-delivery-type-flat"
                    v-html="translate('additionalInfo.orderTypeFlat')"
                  />
                  <span
                    v-else-if="option.value === OrderDeliveryType.House"
                    data-test-id="order-user-delivery-type-house"
                    v-html="translate('additionalInfo.orderTypeHouse')"
                  />
                  <span
                    v-else-if="option.value === OrderDeliveryType.Org"
                    data-test-id="order-user-delivery-type-org"
                    v-html="translate('additionalInfo.orderTypeOrg')"
                  />
                </template>
              </arora-radio-input>
            </div>
          </div>
        </div>

        <transition
          appear
          mode="out-in"
          name="slidedown"
        >
          <div
            v-if="newAddress.OrderDeliveryType === OrderDeliveryType.Flat"
            class="v-row"
            data-test-id="additional-order-delivery-type-flat"
          >
            <div class="v-col-12 v-col-sm-6">
              <v-input
                class-name="v-arora-input"
                required
                v-form-validator="{
                  Form: validatorForm,
                  Value: newAddress.Apartment,
                  Required: true,
                  Validations: ['noSpace', 'length'],
                  MaxLength: 10
                }"
                :label="translate('additionalInfo.apartment')"
                data-test-id="order-user-apartment"
                v-model:text="newAddress.Apartment"
              />
            </div>
            <div class="v-col-12 v-col-sm-6">
              <v-input
                class-name="v-arora-input"
                v-form-validator="{
                  Form: validatorForm,
                  Value: newAddress.Doorway,
                  Required: false,
                  Validations: ['noSpace', 'length'],
                  MaxLength: 10
                }"
                :label="translate('additionalInfo.doorway')"
                data-test-id="order-user-doorway"
                v-model:text="newAddress.Doorway"
              />
            </div>
            <div class="v-col-12 v-col-sm-6">
              <v-input
                class-name="v-arora-input"
                v-form-validator="{
                  Form: validatorForm,
                  Value: newAddress.Floor,
                  Required: false,
                  Validations: ['noSpace', 'length'],
                  MaxLength: 10
                }"
                :label="translate('additionalInfo.floor')"
                data-test-id="order-user-floor"
                v-model:text="newAddress.Floor"
              />
            </div>
            <div class="v-col-12 v-col-sm-6">
              <v-input
                class-name="v-arora-input"
                v-form-validator="{
                  Form: validatorForm,
                  Value: newAddress.Intercom,
                  Required: false,
                  Validations: ['noSpace', 'length'],
                  MaxLength: 10
                }"
                :label="translate('additionalInfo.intercom')"
                data-test-id="order-user-intercom"
                v-model:text="newAddress.Intercom"
              />
            </div>
          </div>
          <div
            v-else-if="newAddress.OrderDeliveryType === OrderDeliveryType.Org"
            class="v-row"
            data-test-id="additional-order-delivery-type-org"
          >
            <div class="v-col-12 v-col-sm-6">
              <v-input
                class-name="v-arora-input"
                v-form-validator="{
                  Form: validatorForm,
                  Value: newAddress.Apartment,
                  Required: false,
                  Validations: ['noSpace', 'length'],
                  MaxLength: 10
                }"
                :label="translate('additionalInfo.office')"
                data-test-id="order-user-office"
                v-model:text="newAddress.Apartment"
              />
            </div>
            <div class="v-col-12 v-col-sm-6">
              <v-input
                class-name="v-arora-input"
                v-form-validator="{
                  Form: validatorForm,
                  Value: newAddress.Doorway,
                  Required: false,
                  Validations: ['noSpace', 'length'],
                  MaxLength: 10
                }"
                :label="translate('additionalInfo.doorway')"
                data-test-id="order-user-doorway"
                v-model:text="newAddress.Doorway"
              />
            </div>
            <div class="v-col-12 v-col-sm-6">
              <v-input
                class-name="v-arora-input"
                v-form-validator="{
                  Form: validatorForm,
                  Value: newAddress.Floor,
                  Required: false,
                  Validations: ['digits', 'length'],
                  MaxLength: 10
                }"
                :label="translate('additionalInfo.floor')"
                data-test-id="order-user-floor"
                v-model:text="newAddress.Floor"
              />
            </div>
            <div class="v-col-12 v-col-sm-6">
              <v-input
                class-name="v-arora-input"
                v-form-validator="{
                  Form: validatorForm,
                  Value: newAddress.Intercom,
                  Required: false,
                  Validations: ['noSpace', 'length'],
                  MaxLength: 10
                }"
                :label="translate('additionalInfo.intercom')"
                data-test-id="order-user-intercom"
                v-model:text="newAddress.Intercom"
              />
            </div>
          </div>
        </transition>

        <div
          v-if="orderDeliveryTypes.length === 0"
          class="v-row"
        >
          <div
            v-if="!appConfig.VueSettingsPreRun.DoorwayHidden"
            class="v-col-12 v-col-sm-6 v-col-md-3"
          >
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: validatorForm,
                Value: newAddress.Doorway,
                Required: appConfig.VueSettingsPreRun.DoorwayRequired,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.doorway')"
              :required="appConfig.VueSettingsPreRun.DoorwayRequired"
              v-model:text="newAddress.Doorway"
            />
          </div>
          <div
            v-if="!appConfig.VueSettingsPreRun.BuildingHidden"
            class="v-col-12 v-col-sm-6 v-col-md-3"
          >
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: validatorForm,
                Value: newAddress.Building,
                Required: appConfig.VueSettingsPreRun.BuildingRequired,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.building')"
              :required="appConfig.VueSettingsPreRun.BuildingRequired"
              v-model:text="newAddress.Building"
            />
          </div>
          <div
            v-if="!appConfig.VueSettingsPreRun.ApartmentHidden"
            class="v-col-12 v-col-sm-6 v-col-md-3"
          >
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: validatorForm,
                Value: newAddress.Apartment,
                Required: appConfig.VueSettingsPreRun.ApartmentRequired,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.apartment')"
              :required="appConfig.VueSettingsPreRun.ApartmentRequired"
              v-model:text="newAddress.Apartment"
            />
          </div>
          <div
            v-if="!appConfig.VueSettingsPreRun.IntercomHidden"
            class="v-col-12 v-col-sm-6 v-col-md-3"
          >
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: validatorForm,
                Value: newAddress.Intercom,
                Required: appConfig.VueSettingsPreRun.IntercomRequired,
                Validations: ['noSpace', 'length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.intercom')"
              :required="appConfig.VueSettingsPreRun.IntercomRequired"
              v-model:text="newAddress.Intercom"
            />
          </div>
          <div
            v-if="!appConfig.VueSettingsPreRun.FloorHidden"
            class="v-col-12 v-col-sm-6 v-col-md-3"
          >
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: validatorForm,
                Value: newAddress.Floor,
                Required: appConfig.VueSettingsPreRun.FloorRequired,
                Validations: ['length'],
                MaxLength: 10
              }"
              :label="translate('additionalInfo.floor')"
              :required="appConfig.VueSettingsPreRun.FloorRequired"
              v-model:text="newAddress.Floor"
            />
          </div>
        </div>

        <div class="v-row">
          <div class="v-col-12">
            <v-input
              class-name="v-arora-input"
              v-form-validator="{
                Form: validatorForm,
                Value: newAddress.Comment,
                Required: false,
                Validations: ['length'],
                MaxLength: 255
              }"
              :label="translate('comment.comment')"
              v-model:text="newAddress.Comment"
            />
          </div>
        </div>
      </arora-summary-details>
    </div>
    <div class="v-saved-address-wrapper__map v-mb-sm">
      <maps-yandex-maps-polygon-common
        :click-callback="clickCallback"
        :coords="coords"
        type="saved"
      >
        <maps-common-delivery-icon />
      </maps-yandex-maps-polygon-common>
    </div>
  </div>

  <div class="v-row">
    <transition
      appear
      mode="out-in"
      name="slidedown"
    >
      <div
        v-if="fieldsNotValidated"
        class="v-col-12 v-mb-md v-d-flex v-align-items-center"
      >
        <icon-general-information-circle class="v-error-color v-mr-xs" />
        <span
          class="v-error-color"
          v-html="translate('yandexMapsSavedAddressesPopup.notAllFilled')"
        />
      </div>
    </transition>
    <div class="v-offset-3 v-offset-md-4 v-col-4">
      <arora-button
        class-name="v-btn v-btn-text v-keep-fz v-no-underline v-w-100 v-h-100"
        ignore-settings
        :label="translate('yandexMapsSavedAddressesPopup.cancel')"
        @click="() => popupStore.closePopup()"
      />
    </div>

    <div class="v-col-5 v-col-md-4">
      <arora-button
        class-name="v-w-100"
        :disabled="lockButton"
        :label="translate('savedAddressesPage.save')"
        @click="async () => await saveAddress()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Guid, OrderDeliveryType } from '~api/consts'

import type { MapsSavedAddress } from '~types/accountStore'
import type { DistrictsPrice, Restaurant } from '~types/addressStore'
import type { AddressCoordinates } from '~types/clientStore'
import type { TypeaheadOptions } from '~types/common'
import type { Geocode } from '~types/mapsStore'

import type { DomEvent, DomEventHandlerObject } from '@yandex/ymaps3-types/imperative/YMapListener'

import {
  type GUID,
  useCommon,
  useValidationStore,
  useWindowSize,
  vFormValidator,
  VInput
} from '@arora/common'
import { getActivePinia } from 'pinia'

const { address } = defineProps<{
  address: MapsSavedAddress | undefined
}>()

const { translate, clean } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()
const { redirectToRestaurant } = useAddressSelector()
const { isSmall } = useWindowSize()

const validatorForm = 'yaMapsSavedAddress'

const addressStore = useAddressStore()
const accountStore = useAccountStore()
const mapsStore = useMapsStore()
const popupStore = usePopupStore()
const validationStore = useValidationStore(getActivePinia())

const appConfig = useAppConfig()
const { fromPopup } = useInstance()

const orderDeliveryTypes = computed<number[]>(() => {
  const result: number[] = []

  if (!appConfig.VueSettingsPreRun.OrderDeliveryTypeEnable) return result

  if (appConfig.VueSettingsPreRun.OrderDeliveryTypeShowFlat) result.push(OrderDeliveryType.Flat)
  if (appConfig.VueSettingsPreRun.OrderDeliveryTypeShowHouse) result.push(OrderDeliveryType.House)
  if (appConfig.VueSettingsPreRun.OrderDeliveryTypeShowOrg) result.push(OrderDeliveryType.Org)

  return result
})

const restaurantData = computed<Restaurant[]>(() => {
  const restaurants: Restaurant[] = addressStore.Restaurants.data ?? []

  let total = [] as Restaurant[]

  if (restaurants) {
    total = [
      ...total,
      ...Object.values(restaurants).filter((rest) => {
        return rest.Active && rest.ShowRestaurantInSelect
      })
    ]
  }

  return total.sort((a, b) => {
    return a.SortWeight === undefined || a.SortWeight > b.SortWeight ? 1 : -1 //sort as in the back
  })
})

const coords = ref<AddressCoordinates>()

const newAddress = ref<MapsSavedAddress>({
  AddressCoordinates: [],
  AddressType: '',
  Apartment: '',
  Building: '',
  City: '',
  Comment: '',
  Corpus: '',
  District: '',
  Doorway: '',
  Floor: '',
  House: '',
  ID: Guid.Empty,
  Intercom: '',
  Office: '',
  OrderDeliveryType: 0,
  Region: '',
  Street: '',
  ZipCode: '',
  restaurant: ''
})

const fieldsNotValidated = ref<boolean>(false)

const userStreet = ref<string>(newAddress.value.Street)
const isUserStreetCorrect = ref<boolean>(false)
const isUserStreetGeocoded = ref<boolean>(true)

const userHouse = ref<string>(newAddress.value.House)
const isUserHouseCorrect = ref<boolean>(false)

async function clickCallback(object: DomEventHandlerObject | undefined, event: DomEvent): Promise<void> {
  const district: DistrictsPrice | undefined = object?.entity?.properties?.district as
    | DistrictsPrice
    | undefined
  if (district) {
    mapsStore.geocodeYmap(event.coordinates.toString(), null).then((geocode: Geocode) => {
      const member = geocode.response.GeoObjectCollection.featureMember[0]
      const coords = member.GeoObject.Point.pos.split(' ')
      mapsStore.updateSavedAddressCoordsWithGeocode(
        [Number.parseFloat(coords[0]), Number.parseFloat(coords[1])],
        member.GeoObject,
        newAddress.value
      )

      userStreet.value = newAddress.value.Street
      isUserStreetCorrect.value = !stringIsNullOrWhitespace(newAddress.value.Street)
      isUserStreetGeocoded.value = !stringIsNullOrWhitespace(newAddress.value.Street)
      userHouse.value = newAddress.value.House
      isUserHouseCorrect.value = !stringIsNullOrWhitespace(newAddress.value.House)
    })
  }
}

async function userInputStreetChanged(value: string): Promise<TypeaheadOptions<null>[]> {
  isUserStreetCorrect.value = false

  userStreet.value = value

  if (value.length < appConfig.VueSettingsPreRun.StreetListAutocompleteMinChars) {
    return []
  }

  const suggestResponseItems = await mapsStore.geocodeYMapSuggest(value)

  const result: TypeaheadOptions<null>[] = []

  for (const suggestResponseItem of suggestResponseItems) {
    result.push({
      ID: (suggestResponseItem.title?.text ?? '') as GUID,
      title: suggestResponseItem.title?.text,
      subtitle: suggestResponseItem.subtitle?.text,
      item: null,
      onClick: () => {
        mapsStore
          .geocodeYmap(
            `${suggestResponseItem.subtitle?.text ?? ''} ${suggestResponseItem.value}`,
            'street'
          )
          .then((geocode: Geocode) => {
            isUserStreetGeocoded.value = true

            if (geocode.response.GeoObjectCollection.featureMember.length === 0) {
              console.error('no results in geocode', geocode)

              if (!fromPopup.value) {
                popupStore.showError(translate('yandexMapsAutocomplete.incorrectGeocode'))
              }
              isUserStreetGeocoded.value = false

              return
            }

            const member = geocode.response.GeoObjectCollection.featureMember.find(
              (mem) =>
                mem.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'airport' ||
                mem.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'district' ||
                mem.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'house' ||
                mem.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'railway_station' ||
                mem.GeoObject.metaDataProperty.GeocoderMetaData.kind === 'street'
            )
            const coords = member?.GeoObject.Point.pos.split(' ')
            if (
              member &&
              coords &&
              !mapsStore.updateSavedAddressCoordsWithGeocode(
                [Number.parseFloat(coords[0]), Number.parseFloat(coords[1])],
                member.GeoObject,
                newAddress.value
              )
            ) {
              if (!fromPopup.value) {
                popupStore.showError(translate('yandexMapsAutocomplete.incorrectGeocode'))
              }
              isUserStreetGeocoded.value = false
            }
          })
          .then(() => {
            userStreet.value = newAddress.value.Street
            userHouse.value = newAddress.value.House
            isUserHouseCorrect.value = !stringIsNullOrWhitespace(newAddress.value.House)
          })
        isUserStreetCorrect.value = true
      }
    })
  }

  return result
}

async function userInputHouseChanged(value: string): Promise<TypeaheadOptions<null>[]> {
  isUserHouseCorrect.value = false
  userHouse.value = value

  const result: TypeaheadOptions<null>[] = []
  const geocode = await mapsStore.geocodeYmap(`${newAddress.value.Street} ${value}`, 'house')

  for (const member of geocode.response.GeoObjectCollection.featureMember) {
    const house = member.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components.find(
      (x) => x.kind === 'house'
    )

    if (house)
      result.push({
        ID: `${house.name}-${house.kind}` as GUID,
        title: house.name,
        subtitle: '',
        item: null,
        onClick: () => {
          const coords = member.GeoObject.Point.pos.split(' ')
          mapsStore.updateSavedAddressCoordsWithGeocode(
            [Number.parseFloat(coords[0]), Number.parseFloat(coords[1])],
            member.GeoObject,
            newAddress.value
          )
          userHouse.value = newAddress.value.House
          isUserHouseCorrect.value = true
        }
      })
  }

  return result
}

const lockButton = ref<boolean>(false)

async function saveAddress(): Promise<void> {
  lockButton.value = true
  try {
    fieldsNotValidated.value = !validationStore.formPassedCheck(validatorForm)

    if (fieldsNotValidated.value) return

    newAddress.value.Street = clean(newAddress.value.Street)
    newAddress.value.House = clean(newAddress.value.House)
    newAddress.value.Apartment = clean(newAddress.value.Apartment)
    newAddress.value.Corpus = clean(newAddress.value.Corpus)
    newAddress.value.Building = clean(newAddress.value.Building)
    newAddress.value.Doorway = clean(newAddress.value.Doorway)
    newAddress.value.Intercom = clean(newAddress.value.Intercom)
    newAddress.value.Floor = clean(newAddress.value.Floor)
    newAddress.value.Comment = clean(newAddress.value.Comment)

    if (Guid.IsNullOrEmpty(newAddress.value.ID))
      await accountStore.addSavedAddress(
        newAddress.value,
        appConfig.RestaurantSettingsPreRun.OneStageCart
      )
    else
      await accountStore.changeSavedAddress(
        newAddress.value,
        appConfig.RestaurantSettingsPreRun.OneStageCart
      )

    popupStore.showSuccess(translate('savedAddressesPopup.success'))
    popupStore.closePopup()
  } catch (error) {
    popupStore.closePopup()
    if ('message' in error) {
      popupStore.showError(error.message.toString())
    } else {
      popupStore.showException(error)
    }
  } finally {
    lockButton.value = false
  }
}

watch(
  () => newAddress.value.Street,
  (newValue) => {
    if (newValue) {
      userStreet.value = newValue
      isUserStreetCorrect.value = true
    }
  }
)

watch(
  () => newAddress.value.House,
  (newValue) => {
    if (newValue) {
      userHouse.value = newValue
      isUserHouseCorrect.value = true
    }
  }
)

onMounted(async () => {
  await Promise.all([addressStore.initRestaurant(), addressStore.initCities()])
  if (address) newAddress.value = { ...address }

  const result: AddressCoordinates = {}

  if (newAddress.value?.AddressCoordinates) {
    result.Latitude = newAddress.value.AddressCoordinates[0]
    result.Longitude = newAddress.value.AddressCoordinates[1]
  } else if (
    !stringIsNullOrWhitespace(newAddress.value.Street) &&
    !stringIsNullOrWhitespace(newAddress.value.House)
  ) {
    mapsStore
      .geocodeYMapSearch(`${newAddress.value.Street} ${newAddress.value.House}`)
      .then((search) => {
        if (search[0].geometry) {
          result.Longitude = search[0].geometry.coordinates[0]
          result.Latitude = search[0].geometry.coordinates[1]
        }
      })
  }

  coords.value = result
})

function onChangeCity(id: GUID): void {
  if (!restaurantData.value) return
  const rest = restaurantData.value.find((rest: Restaurant) => {
    return rest.ID === id
  })
  if (!rest) return

  popupStore.closePopup()

  redirectToRestaurant(rest)
}

const selectedCityValue = ref(appConfig.CurrentRestaurantId)
const selectedCity = computed<GUID>({
  get() {
    return selectedCityValue.value ?? Guid.Empty
  },
  set(newValue: GUID) {
    selectedCityValue.value = newValue
    onChangeCity(selectedCityValue.value)
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-saved-address-popup-title {
  font-size: variables.$TextSizeH3;
  font-weight: 700;
}

.v-saved-address-wrapper {
  display: grid;
  grid-template-columns: minmax(200px, 3fr) minmax(100px, 1fr) minmax(150px, 1.5fr);
  grid-template-rows: auto auto auto;
  gap: 0 10px;
  grid-template-areas:
    'street house corpus'
    'additional additional additional'
    'map map map';

  @include mixins.sm {
    grid-template-columns: repeat(2, fit-content(50%));
    gap: 10px 30px;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'map map'
      'street street'
      'house corpus'
      'additional additional';
  }

  &--no_corpus {
    grid-template-areas:
      'street street house'
      'additional additional additional'
      'map map map';
  }

  &__street {
    grid-area: street;
  }
  &__house {
    grid-area: house;
  }
  &__corpus {
    grid-area: corpus;
  }
  &__additional {
    grid-area: additional;
  }
  &__map {
    grid-area: map;
  }
}
</style>
