<i18n>
ru:
  birthdayWarning: Дата рождения указывается один раз и её нельзя изменить
  cancel: Отмена
  edit: Редактирование профиля
  notifyRequired: Для сохранения необходимо дать согласие на рассылку
  save: Сохранить
  successMessage: Данные профиля обновлены
ua:
  birthdayWarning: Дата народження вказується один раз і її неможливо змінити
  cancel: Скасування
  edit: Редагування профілю
  notifyRequired: Для збереження необхідно погодитися на розсилку
  save: Зберегти
  successMessage: Дані профілю оновлено
us:
  birthdayWarning: Birthday is allowed to be entered once and it cannot be changed
  cancel: Cancel
  edit: Edit profile
  notifyRequired: 'To save, consent to receiving newsletters is required'
  save: Save
  successMessage: Profile data updated
</i18n>

<template>
  <div class="v-d-flex v-flex-column v-edit-profile">
    <div
      class="v-popup-head v-title v-edit-profile__title v-mb-sm"
      v-html="translate('editProfile.edit')"
    />
    <template v-if="editedProfile">
      <v-input
        v-if="appConfig.VueSettingsPreRun.CabinetShowName"
        class-name="v-arora-input"
        v-form-validator="{
          Form: validatorForm,
          Value: name,
          Required: appConfig.VueSettingsPreRun.CabinetNameRequired,
          Validations: ['text', 'length'],
          MaxLength: 60
        }"
        :label="translate('profile.name')"
        :required="appConfig.VueSettingsPreRun.CabinetNameRequired"
        v-model:text="name"
      />
      <v-input
        v-if="appConfig.VueSettingsPreRun.CabinetShowSurname"
        class-name="v-arora-input"
        v-form-validator="{
          Form: validatorForm,
          Value: surname,
          Required: appConfig.VueSettingsPreRun.CabinetSurnameRequired,
          Validations: ['text', 'length'],
          MaxLength: 60
        }"
        :label="translate('profile.surname')"
        :required="appConfig.VueSettingsPreRun.CabinetSurnameRequired"
        v-model:text="surname"
      />
      <v-email-input
        v-if="appConfig.VueSettingsPreRun.CabinetShowEmail"
        class-name="v-arora-input"
        :required="
          appConfig.VueSettingsPreRun.CabinetEmailRequired ||
          appConfig.VueSettingsPreRun.CabinetNotifyEmailRequired ||
          (appConfig.VueSettingsPreRun.CabinetShowNotifyEmail && editedProfile.RecieveNewsEmail)
        "
        v-form-validator="{
          Form: validatorForm,
          Value: email,
          Required:
            appConfig.VueSettingsPreRun.CabinetEmailRequired ||
            appConfig.VueSettingsPreRun.CabinetNotifyEmailRequired ||
            (appConfig.VueSettingsPreRun.CabinetShowNotifyEmail && editedProfile.RecieveNewsEmail),
          RequiredText: editedProfile.RecieveNewsEmail
            ? translate('accountManagement.emailNotifyRequired')
            : undefined,
          Validations: ['email', 'length'],
          MaxLength: 60
        }"
        :label="translate('profile.email')"
        v-model:text="email"
      />
      <arora-radio-input
        v-if="appConfig.VueSettingsPreRun.CabinetShowGender"
        as-column
        v-form-validator="{
          Form: validatorForm,
          Value: gender?.toString() ?? null,
          Required: appConfig.VueSettingsPreRun.CabinetGenderRequired,
          Validations: []
        }"
        :label="translate('accountManagement.gender')"
        :options="[true, false]"
        :required="appConfig.VueSettingsPreRun.CabinetGenderRequired"
        v-model:selected="gender"
      >
        <template #option="option">
          <span
            v-html="
              translate(option.value ? 'accountManagement.genderMale' : 'accountManagement.genderFemale')
            "
          />
        </template>
      </arora-radio-input>
      <arora-date-input
        v-if="appConfig.VueSettingsPreRun.CabinetShowBDay"
        v-form-validator="{
          Form: validatorForm,
          Value: bday,
          Required: appConfig.VueSettingsPreRun.CabinetBDayRequired,
          Validations: ['birthday']
        }"
        :disabled="editedProfile.BirthDayHasBeenChanged"
        :label="translate('profile.bday')"
        :required="appConfig.VueSettingsPreRun.CabinetBDayRequired"
        v-model:text="bday"
      >
        <template #right-end>
          <common-popover
            class="v-ml-xs"
            placement="top"
          >
            <template #content>
              {{ translate('editProfile.birthdayWarning') }}
            </template>
          </common-popover>
        </template>
      </arora-date-input>
      <div
        v-if="
          appConfig.VueSettingsPreRun.CabinetShowNotifyEmail ||
          appConfig.VueSettingsPreRun.CabinetShowNotifySms
        "
        class="v-w-100 v-edit-profile__notify"
      >
        <arora-switch
          v-if="appConfig.VueSettingsPreRun.CabinetShowNotifyEmail"
          v-form-validator="{
            Form: validatorForm,
            Value: editedProfile.RecieveNewsEmail ? '#' : '',
            Required: appConfig.VueSettingsPreRun.CabinetNotifyEmailRequired,
            RequiredText: translate('editProfile.notifyRequired'),
            Validations: []
          }"
          :label="translate('profile.emailNewsletter')"
          v-model:checked="editedProfile.RecieveNewsEmail"
        />

        <arora-switch
          v-if="appConfig.VueSettingsPreRun.CabinetShowNotifySms"
          class-name="v-row v-align-items-center"
          v-form-validator="{
            Form: validatorForm,
            Value: editedProfile.RecieveNewsSms ? '#' : '',
            Required: appConfig.VueSettingsPreRun.CabinetNotifySmsRequired,
            RequiredText: translate('editProfile.notifyRequired'),
            Validations: []
          }"
          :label="translate('profile.smsNewsletter')"
          v-model:checked="editedProfile.RecieveNewsSms"
        />
      </div>
      <div class="v-w-100 v-d-flex v-justify-content-between v-popup-buttons">
        <arora-button
          class-name="v-btn v-btn-link v-btn-text v-no-underline"
          ignore-settings
          :label="translate('editProfile.cancel')"
          @click="async () => await popupStore.closePopup()"
        />
        <arora-button
          class-name="v-font-weight-600"
          :label="translate('editProfile.save')"
          @click="async () => await save()"
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { Profile } from '~types/accountStore'

import { useCommon, useValidationStore, VEmailInput, vFormValidator, VInput } from '@arora/common'
import { getActivePinia } from 'pinia'

const accountStore = useAccountStore()
const popupStore = usePopupStore()
const validationStore = useValidationStore(getActivePinia())

const appConfig = useAppConfig()
const { clean, translate } = useI18nSanitized()
const { fromMillisInZone } = useDateTime()
const { capitalLetter, stringIsNullOrWhitespace } = useCommon()

const validatorForm = 'editProfile'

const editedProfile = ref<Profile | undefined>()

const name = computed<string | null | undefined>({
  get() {
    if (!appConfig.VueSettingsPreRun.CabinetShowName || !editedProfile.value) return null

    return editedProfile.value.Name
  },
  set(value: string | null | undefined) {
    if (!editedProfile.value) return

    if (stringIsNullOrWhitespace(value)) {
      editedProfile.value.Name = ''
    } else {
      editedProfile.value.Name = capitalLetter(clean(value ?? ''))
    }
  }
})

const surname = computed<string | null | undefined>({
  get() {
    if (!appConfig.VueSettingsPreRun.CabinetShowSurname || !editedProfile.value) return null

    return editedProfile.value.Surname
  },
  set(value: string | null | undefined) {
    if (!editedProfile.value) return

    if (stringIsNullOrWhitespace(value)) {
      editedProfile.value.Surname = ''
    } else {
      editedProfile.value.Surname = capitalLetter(clean(value ?? ''))
    }
  }
})

const email = computed<string | null | undefined>({
  get() {
    if (!appConfig.VueSettingsPreRun.CabinetShowEmail || !editedProfile.value) return null

    return editedProfile.value.Email
  },
  set(value: string | null | undefined) {
    if (!editedProfile.value) return

    if (stringIsNullOrWhitespace(value)) {
      editedProfile.value.Email = ''
    } else {
      editedProfile.value.Email = clean(value ?? '')
    }
  }
})

const gender = computed<boolean | null>({
  get() {
    if (!appConfig.VueSettingsPreRun.CabinetShowGender || !editedProfile.value) return null

    return editedProfile.value.MaleIsNull ? null : editedProfile.value.Male
  },
  set(value: boolean | null) {
    if (editedProfile.value) {
      editedProfile.value.Male = value ?? false
      editedProfile.value.MaleIsNull = value === null
    }
  }
})

const bday = computed<string | null>({
  get() {
    if (!appConfig.VueSettingsPreRun.CabinetShowBDay || !editedProfile.value) return null

    return editedProfile.value.Birthday < -2208988800
      ? null
      : fromMillisInZone(
          editedProfile.value.Birthday * 1000,
          appConfig.RestaurantSettingsPreRun.GMT
        ).toFormat('yyyy-MM-dd')
  },
  set(value: string | null) {
    if (editedProfile.value && value && !editedProfile.value.BirthDayHasBeenChanged) {
      editedProfile.value.Birthday = new Date(value).getTime() / 1000
    }
  }
})

onMounted(() => {
  if (!accountStore.Profile.data) return

  editedProfile.value = {
    ...accountStore.Profile.data
  }

  if (accountStore.Profile.data.RecieveNewsEmail) {
    validationStore.formPassedCheck(validatorForm)
  }
})
async function save(): Promise<void> {
  if (!editedProfile.value) return

  if (!validationStore.formPassedCheck(validatorForm)) return

  const popupStore = usePopupStore()

  const result = await accountStore.saveProfile(editedProfile.value, bday.value)

  if (result.code === 0) {
    await popupStore.closePopup()
    popupStore.showSuccess(translate('editProfile.successMessage'))
  } else {
    await popupStore.closePopup()
    popupStore.showException(result)
  }
}
</script>

<style lang="scss">
@use '~/assets/variables';

.v-edit-profile {
  &__title {
    font-size: variables.$TextSizeH3;
    font-weight: 600;
  }

  &__notify {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding: 16px 0;
  }

  .v-popup-buttons {
    .v-btn {
      font-size: 1.15rem;
    }
  }
}
</style>
