<i18n>
ru:
  addToCart: В корзину
  addToCartConstructor: В корзину
  addToCartHalf: Собрать
  addToCartLunch: Собрать
  addToCartQuarter: Собрать
  errorNotAvailableForDelivery: Не доступно для доставки
  errorNotAvailableForSelfService: Не доступно для самовывоза
  errorOtherBirthdayGiftInCart: Присутствует другой подарок на день рождения
  errorOtherDiscountProdInCart: Присутствует другой акционный продукт
  errorOtherGiftProdInCart: Присутствует другой подарок
  errorProductLimitedForOneCart: Ограничение на количество товара
  more: Ещё
ua:
  addToCart: В кошик
  addToCartConstructor: В кошик
  addToCartHalf: Зібрати
  addToCartLunch: Зібрати
  addToCartQuarter: Зібрати
  errorNotAvailableForDelivery: Неможливо доставити
  errorNotAvailableForSelfService: Неможливо забрати своїми силами
  errorOtherBirthdayGiftInCart: Є інший подарунок на день народження
  errorOtherDiscountProdInCart: Є інший акційний товар
  errorOtherGiftProdInCart: Є інший подарунок
  errorProductLimitedForOneCart: Обмеження на кількість товару
  more: Ще
us:
  addToCart: Add to Cart
  addToCartConstructor: Add to Cart
  addToCartHalf: Assemble
  addToCartLunch: Assemble
  addToCartQuarter: Assemble
  errorNotAvailableForDelivery: Not available for delivery
  errorNotAvailableForSelfService: Not available for pick-up
  errorOtherBirthdayGiftInCart: Another birthday gift added
  errorOtherDiscountProdInCart: Another promotional product added
  errorOtherGiftProdInCart: Another gift added
  errorProductLimitedForOneCart: Limited quantity of product
  more: More
</i18n>

<template>
  <ClientOnly
    :fallback="translate('addToCartButton.addToCart')"
    fallback-tag="div"
  >
    <template #fallback>
      <arora-button
        :class-name="isProductInPoints && !isEnoughPointsToBuy ? 'v-btn-gray v-pointer-events-none' : ''"
        :label="buttonText"
        @click="async () => await buttonClick()"
      />
    </template>
    <div class="v-elements-overlay">
      <transition
        appear
        mode="out-in"
        name="fade"
      >
        <div
          v-if="
            appConfig.VueSettingsPreRun.MenuItemInCartBehavior === 'arithmetic' &&
            productsInCart.length > 0
          "
          class="v-overlay-element"
        >
          <arora-stepper
            :disable-plus-button="
              !isEnoughPointsToBuy ||
              (productInList.MaxInCart > 0 && productsInCart[0].Count >= productInList.MaxInCart)
            "
            :class="{ 'v-w-100': isProductInPoints }"
            :data-test-id="`add-to-cart-button-${productsInCart[0].ID}`"
            :disabled="productsInCart[0].FixedCount"
            :label="translate('addToCartButton.addToCart')"
            :on-decrement="() => setItemCountLocal(false)"
            :on-increment="() => setItemCountLocal(true)"
            :value="productInCartCount"
          />
        </div>
        <div
          v-else
          class="v-overlay-element"
        >
          <arora-button
            :class-name="
              isProductInPoints && !isEnoughPointsToBuy
                ? 'v-add-to-cart-button v-btn-gray v-pointer-events-none'
                : 'v-add-to-cart-button'
            "
            :data-test-id="dataTestId"
            :label="buttonText"
            @click="async () => await buttonClick()"
          >
            <template v-if="isProductInPoints">
              <template v-if="isEnoughPointsToBuy">
                <common-currency
                  show-points
                  :amount="
                    productInList.PriceInPoints +
                    (menuStore.SelectedOptionsPerProduct.get(productInList.ID)?.PriceInPoints ?? 0)
                  "
                />
              </template>
              <template v-else>
                <div class="v-align-currency-center">
                  <span
                    class="v-mr-xxs"
                    v-html="translate('addToCartButton.more')"
                  />
                  <common-currency
                    show-points
                    :amount="
                      productInList.PriceInPoints +
                      (menuStore.SelectedOptionsPerProduct.get(productInList.ID)?.PriceInPoints ?? 0) +
                      (clientStore.ClientState.data?.Cart?.ToPayWithPoints.Amount ?? 0) -
                      PointsBalance
                    "
                  />
                </div>
              </template>
            </template>
          </arora-button>
        </div>
      </transition>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { FirstAddType, ProductApiType } from '~api/consts'

import type { CartItem, ModifierCounts } from '~types/clientStore'
import type { AnyProductCard } from '~types/props'

import type { AutoTest } from '@arora/common'

import { storeToRefs } from 'pinia'

const {
  isRecommendation,
  productInList,
  cardClick,
  isProductInPoints = false
} = defineProps<
  AnyProductCard &
    AutoTest & {
      isProductInPoints?: boolean
    }
>()

const appConfig = useAppConfig()
const { deleteItem } = useCartItems()
const { translate } = useI18nSanitized()
const { addToCart, setItemCount } = useCartFunctionsWithDialogs()
const { setProductModifiersToZero, getSameProductsInCart } = useProduct()

const clientStore = useClientStore()
const menuStore = useMenuStore()
const accountStore = useAccountStore()
const { isLoggedIn, PointsBalance } = storeToRefs(accountStore)
const productInCartCount = computed<number>(() => {
  return productsInCart.value.reduce(
    (partialSum, productInCartSingle) => partialSum + productInCartSingle.Count,
    0
  )
})

const buttonText = computed<string>(() => {
  switch (productInList.ProductApiType) {
    case ProductApiType.Incorrect:
      return translate('productInListPage.incorrect')
    case ProductApiType.Lunch:
      return translate('addToCartButton.addToCartLunch')
    case ProductApiType.Half:
      return translate('addToCartButton.addToCartHalf')
    case ProductApiType.Quarter:
      return translate('addToCartButton.addToCartQuarter')
    case ProductApiType.Constructor:
    case ProductApiType.BigConstructor:
      return translate('addToCartButton.addToCartConstructor')
    default:
      return translate('addToCartButton.addToCart')
  }
})

const productsInCart = ref<CartItem[]>(getSameProductsInCart(productInList.ID))
watch(
  () => getSameProductsInCart(productInList.ID),
  (newContent: CartItem[] | null | undefined, oldContent: CartItem[] | null | undefined) => {
    if (!oldContent) return // after initialization oldContent is null
    if (!newContent) return // in case of client state update error
    productsInCart.value = getSameProductsInCart(productInList.ID)
  }
)
const isEnoughPointsToBuy = computed<boolean>(() => {
  const optionOfProductPriceInPoints =
    menuStore.SelectedOptionsPerProduct.get(productInList.ID)?.PriceInPoints ?? 0

  if ((productInList.PriceInPoints === 0 && optionOfProductPriceInPoints === 0) || !isLoggedIn.value)
    return true

  return (
    (clientStore?.ClientState?.data?.Cart?.ToPayWithPoints?.Amount ?? 0) +
      productInList.PriceInPoints +
      optionOfProductPriceInPoints <=
    PointsBalance.value
  )
})
async function setItemCountLocal(increment: boolean): Promise<void> {
  if (productsInCart.value.length === 0) return

  const firstProductInCart = productsInCart.value[0]

  if (increment) {
    await setItemCount(
      firstProductInCart.ID,
      firstProductInCart.RealCount + 1,
      firstProductInCart.GroupID,
      firstProductInCart.Name
    )
  } else if (firstProductInCart.RealCount === 1) {
    await deleteItem(firstProductInCart, false)
    setProductModifiersToZero(productInList.ID)
  } else {
    await setItemCount(
      firstProductInCart.ID,
      firstProductInCart.RealCount - 1,
      firstProductInCart.GroupID,
      firstProductInCart.Name
    )
  }
}

async function buttonClick(): Promise<void> {
  switch (productInList.ProductApiType) {
    case ProductApiType.Default: {
      if (productsInCart.value.length > 0 && productsInCart.value[0].Modifiers.length === 0) {
        await setItemCountLocal(true)
      } else if (
        (productInList.MinCountModifiers ?? 0) > 0 ||
        productInList.ModifierGroups.some(
          (group) => (group.MinCount ?? 0) > 0 || group.Modifiers.some((mod) => mod.ValueMin > 0)
        )
      ) {
        await cardClick()
      } else {
        let inStoreMap = menuStore.SelectedModifiersPerProduct.get(productInList.ID)

        if (!inStoreMap) {
          menuStore.SelectedModifiersPerProduct.set(productInList.ID, {})
          inStoreMap = {}
        }

        const localRemovableIngredients: ModifierCounts = Object.fromEntries(
          productInList.RemovableIngredients.filter(
            (ing) => ing.ModifierId && inStoreMap && inStoreMap[ing.ModifierId]
          ).map((ing) => [ing.ModifierId, 1])
        )

        await addToCart(
          productInList.ID,
          isRecommendation ? FirstAddType.Recommendation : FirstAddType.Default,
          productInList.GroupID,
          productInList.Name,
          productInList.Price,
          menuStore.SelectedOptionsPerProduct.get(productInList.ID)?.ID,
          localRemovableIngredients,
          productInCartCount.value + 1
        )
        setProductModifiersToZero(productInList.ID)
      }
      break
    }
    default:
      await cardClick() //rest of product types behave similar to card click
  }
}
</script>

<style lang="scss">
.v-add-to-cart-button {
  white-space: nowrap;
}
</style>
